import React from "react";
import { FaTimes } from "react-icons/fa";
import logo from "../../assets/company-logo.png";
import { useGlobalContext } from "../../context/Context";
import { FaChevronDown, FaGifts } from "react-icons/fa";
import ProfileModal from "../Profile-Modal/ProfileModal";
import { Link } from "react-router-dom";
import { ImUsers } from "react-icons/im";
import { GiSoccerBall, GiSoccerKick } from "react-icons/gi";
import { BsFillShareFill, BsGridFill, BsTrophyFill } from "react-icons/bs";
import { AiTwotoneSetting } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import {
  RiAdminFill,
  RiAdvertisementFill,
  RiMessage2Fill,
} from "react-icons/ri";
import { SiExpertsexchange } from "react-icons/si";
import { HiDocumentText } from "react-icons/hi";
import { BiHandicap } from "react-icons/bi";
import { IoDocumentTextSharp } from "react-icons/io5";
import { FaRegClipboard } from "react-icons/fa6";
import { MdGifBox } from "react-icons/md";

const Sidebar = ({ activePage }) => {
  const { setShowNavbar, showNavbar, showProfileModal, setShowProfileModal } =
    useGlobalContext();
  const { name, role } = JSON.parse(sessionStorage.getItem(`adminData`));
  const initials = name.split(` `).map((item) => item.split(``)[0]);

  return (
    <aside className={showNavbar ? `show` : null}>
      <div className="dark-overlay">
        <div className="void" onClick={() => setShowNavbar(false)}></div>
        <div className="logo-close">
          <img src={logo} alt="company logo" />
          <FaTimes className="close-btn" onClick={() => setShowNavbar(false)} />
        </div>

        <div
          className="profile"
          onClick={() => setShowProfileModal(!showProfileModal)}
        >
          <h5 className="name">{`${initials[0]} ${initials[1]}`}</h5>
          <FaChevronDown className="dropdown" />
          {showProfileModal ? <ProfileModal /> : null}
        </div>

        <div className="menu">
          <h3>Menu</h3>
          <ul>
            <li className={activePage === 0 ? `active` : null}>
              <Link to="/dashboard" onClick={() => setShowNavbar(false)}>
                <BsGridFill className="icon" />
                Dashboard
              </Link>
            </li>

            {role === `admin` || role === `publisher-admin` ? (
              <>
                <li className={activePage === 1 ? `active` : null}>
                  <Link
                    to="/expert-singles"
                    onClick={() => setShowNavbar(false)}
                  >
                    <SiExpertsexchange className="icon" />
                    Expert Singles
                  </Link>
                </li>
                <li className={activePage === 2 ? `active` : null}>
                  <Link
                    to="/free-accumulator-tips"
                    onClick={() => setShowNavbar(false)}
                  >
                    <HiDocumentText className="icon" />
                    Free Accumulator Tips
                  </Link>
                </li>
                <li className={activePage === 3 ? `active` : null}>
                  <Link
                    to="/over-1.5-tips"
                    onClick={() => setShowNavbar(false)}
                  >
                    <GiSoccerBall className="icon" />
                    Over 1.5 Tips
                  </Link>
                </li>
                <li className={activePage === 4 ? `active` : null}>
                  <Link
                    to="/over-2.5-tips"
                    onClick={() => setShowNavbar(false)}
                  >
                    <GiSoccerBall className="icon" />
                    Over 2.5 Tips
                  </Link>
                </li>
                <li className={activePage === 5 ? `active` : null}>
                  <Link to="/bttsgg-tips" onClick={() => setShowNavbar(false)}>
                    <GiSoccerBall className="icon" />
                    BTTSGG Tips
                  </Link>
                </li>
                <li className={activePage === 6 ? `active` : null}>
                  <Link
                    to="/double-chance-tips"
                    onClick={() => setShowNavbar(false)}
                  >
                    <GiSoccerBall className="icon" />
                    Double Chance Tips
                  </Link>
                </li>
                <li className={activePage === 7 ? `active` : null}>
                  <Link
                    to="/handicap-tips"
                    onClick={() => setShowNavbar(false)}
                  >
                    <BiHandicap className="icon" />
                    Handicap Tips
                  </Link>
                </li>
              </>
            ) : null}

            {role === `admin` || role === `blogger-admin` ? (
              <>
                <li className={activePage === 8 ? `active` : null}>
                  <Link to="/news-updates" onClick={() => setShowNavbar(false)}>
                    <IoDocumentTextSharp className="icon" />
                    News Updates
                  </Link>
                </li>
              </>
            ) : null}
          </ul>
        </div>

        <div className="management">
          <h3>Management</h3>
          <ul>
            {role === `admin` && (
              <li className={activePage === 9 ? `active` : null}>
                <Link to="/customers" onClick={() => setShowNavbar(false)}>
                  <ImUsers className="icon" />
                  Customers
                </Link>
              </li>
            )}

            {role === `admin` || role === `publisher-admin` ? (
              <>
                <li className={activePage === 10 ? `active` : null}>
                  <Link to="/clubs" onClick={() => setShowNavbar(false)}>
                    <GiSoccerKick className="icon" />
                    Clubs
                  </Link>
                </li>
                <li className={activePage === 11 ? `active` : null}>
                  <Link to="/competitions" onClick={() => setShowNavbar(false)}>
                    <BsTrophyFill className="icon" />
                    Competitions
                  </Link>
                </li>
              </>
            ) : null}

            {role === `admin` && (
              <>
                <li className={activePage === 12 ? `active` : null}>
                  <Link to="/ads" onClick={() => setShowNavbar(false)}>
                    <RiAdvertisementFill className="icon" />
                    Ads
                  </Link>
                </li>
                <li className={activePage === 13 ? `active` : null}>
                  <Link
                    to="/social-media-links"
                    onClick={() => setShowNavbar(false)}
                  >
                    <BsFillShareFill className="icon" />
                    Social Media Links
                  </Link>
                </li>
                <li className={activePage === 14 ? `active` : null}>
                  <Link to="/messages" onClick={() => setShowNavbar(false)}>
                    <RiMessage2Fill className="icon" />
                    Messages
                  </Link>
                </li>
                <li className={activePage === 20 ? `active` : null}>
                  <Link to="/emails" onClick={() => setShowNavbar(false)}>
                    <MdEmail className="icon" />
                    Emails
                  </Link>
                </li>
                <li className={activePage === 15 ? `active` : null}>
                  <Link to="/offers" onClick={() => setShowNavbar(false)}>
                    <FaGifts className="icon" />
                    Offers
                  </Link>
                </li>
                <li className={activePage === 16 ? `active` : null}>
                  <Link to="/pop-ups" onClick={() => setShowNavbar(false)}>
                    <FaRegClipboard className="icon" />
                    Pop-Ups
                  </Link>
                </li>
                <li className={activePage === 17 ? `active` : null}>
                  <Link to="/gifs" onClick={() => setShowNavbar(false)}>
                    <MdGifBox className="icon" />
                    GIFs
                  </Link>
                </li>
                <li className={activePage === 18 ? `active` : null}>
                  <Link to="/admins" onClick={() => setShowNavbar(false)}>
                    <RiAdminFill className="icon" />
                    Admins
                  </Link>
                </li>
                <li className={activePage === 19 ? `active` : null}>
                  <Link to="/extras" onClick={() => setShowNavbar(false)}>
                    <AiTwotoneSetting className="icon" />
                    Extras
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
