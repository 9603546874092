import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';

const EditFreeAccaTip = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [addBookie, setAddBookie] = useState(false);
  const [bookies, setBookies] = useState([]);
  const [editMatch, setEditMatch] = useState(``);

  const fetchTicketDetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/free-accumulator-tips/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTicketDetails(data.tip);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
    //eslint-disable-next-line
  }, [id]);

  const formik = useFormik({
    initialValues: {
      odds: ``,
      matchTime: ``,
    },
    onSubmit() {
      updateTip();
    },
  });

  const updateTip = async () => {
    const { odds, matchTime } = formik.values;

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/free-accumulator-tips/${id}`,
        {
          odds: odds ? odds : ticketDetails.odds,
          matchTime: matchTime ? matchTime : ticketDetails.matchTime,
          bookies: [...ticketDetails.bookies, [...bookies]],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/free-accumulator-tips`);
    } catch (error) {
      console.log(error);
      toast.success(error.response.data.msg);
      setLoading(false);
    }
  };

  const { odds, matchTime, bookies: oldBookies, matches } = ticketDetails;

  const deleteBookie = async (bookieId) => {
    try {
      const { data } = await axios.patch(
        `${baseURL}/free-accumulator-tips/delete-bookie/${bookieId}`,
        { tipId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      fetchTicketDetails();
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const handleBookieDelete = (id) => {
    setBookies(() => bookies.filter((_, index) => index !== id));
  };

  return (
    <main className='view-regular-tip-page'>
      <Navbar
        category={`Management > Free Accumulator Tips >`}
        categoryLink={`/free-accumulator-tips`}
        currentPage={`Edit Tip`}
      />
      <Sidebar activePage={2} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/free-accumulator-tips`)} />
          <h2 className='title'>Edit Tip</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* TICKET INFORMATION BOX */}
            <div className='ticket-details'>
              <h3 className='title'>Tip Details</h3>
              <form action='' onSubmit={formik.handleSubmit}>
                {/* ODDS */}
                <div className='form-control'>
                  <label
                    htmlFor='odds'
                    className={
                      formik.errors.odds && formik.touched.odds ? `error` : ``
                    }
                  >
                    {formik.errors.odds && formik.touched.odds
                      ? formik.errors.odds
                      : `Odds`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='odds'
                    id='odds'
                    value={formik.values.odds}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={odds}
                  />
                </div>

                {/* MATCH TIME */}
                <div className='form-control'>
                  <label
                    htmlFor='matchTime'
                    className={
                      formik.errors.matchTime && formik.touched.matchTime
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.matchTime && formik.touched.matchTime
                      ? formik.errors.matchTime
                      : `Match Time`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='matchTime'
                    id='matchTime'
                    type='datetime-local'
                    value={formik.values.matchTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={moment(matchTime).format(`HH:MM`)}
                  />
                </div>

                {/* BOOKIES */}
                <div className='bookies'>
                  <h3 className='title'>New Bookies</h3>
                  {bookies && (
                    <>
                      {bookies.map((bookie, bookieIndex) => {
                        const { link, code, name } = bookie;
                        return (
                          <div className='bookie' key={bookieIndex}>
                            <div className='name'>
                              <h5>{name}</h5>
                            </div>
                            <div className='code'>
                              <h5>{code}</h5>
                            </div>
                            <div className='link'>
                              <h5>{link}</h5>
                            </div>
                            <div className='delete'>
                              <RiDeleteBin2Fill
                                onClick={() => handleBookieDelete(bookieIndex)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>

                <div className='add-bookie'>
                  <button
                    type='button'
                    className='transparent'
                    onClick={() => setAddBookie(true)}
                  >
                    Add Bookie
                  </button>
                </div>

                <div className='bookies current'>
                  <h3 className='title'>Current Bookies</h3>
                  {oldBookies?.map((bookie) => {
                    const { _id, name, code, link } = bookie;
                    return (
                      <div className='bookie' key={_id}>
                        <div className='name'>
                          <h5>{name}</h5>
                        </div>
                        <div className='code'>{code}</div>
                        <div className='link'>
                          <h5>{link}</h5>
                        </div>
                        <div className='delete'>
                          <RiDeleteBin2Fill onClick={() => deleteBookie(_id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* MATCHES */}
                <div className='matches'>
                  <h3 className='title'>Matches</h3>
                  {matches?.map((match) => {
                    const {
                      _id,
                      matchTime,
                      homeTeam,
                      awayTeam,
                      league,
                      prediction,
                      status,
                    } = match;

                    return (
                      <div className='match' key={_id}>
                        <div className='teams'>
                          <h5>
                            {homeTeam} <span>vs</span> {awayTeam}
                          </h5>
                        </div>
                        <div className='league'>
                          <h5>{league}</h5>
                        </div>
                        <div className='prediction'>
                          <h5>{prediction}</h5>
                        </div>
                        <div className='status'>
                          <h5>{status}</h5>
                        </div>
                        <div className='time'>
                          <h5>{moment(matchTime).format(`lll`)}</h5>
                        </div>
                        <div className='delete'>
                          <FaEdit onClick={() => setEditMatch(_id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update Tip {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
      {addBookie && (
        <AddBookieModal
          bookies={bookies}
          setBookies={setBookies}
          setAddBookie={setAddBookie}
        />
      )}
      {editMatch && (
        <EditMatchModal
          resetFunc={setEditMatch}
          matchId={editMatch}
          tipId={id}
          refreshFunc={fetchTicketDetails}
        />
      )}
    </main>
  );
};

export const AddBookieModal = ({ bookies, setBookies, setAddBookie }) => {
  const [bookie, setBookie] = useState({
    name: ``,
    link: ``,
    code: ``,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBookie({ ...bookie, [name]: value });
  };

  const resetForm = () => {
    setBookie({
      name: ``,
      link: ``,
      code: ``,
    });
  };

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setAddBookie(false);
              resetForm();
            }}
          />
        </div>

        {/* BOOKIE */}
        <div className='form-control'>
          <label htmlFor='name'>Bookie</label>
          <select name='name' id='name' onChange={handleChange}>
            <option defaultChecked hidden>
              Select Bookie
            </option>
            <option value='1xbet'>1xbet</option>
            <option value='zebet'>Zebet</option>
            <option value='paripesa'>Paripesa</option>
            <option value='betwinner'>Betwinner</option>
            <option value='betano'>Betano</option>
          </select>
        </div>

        {/* BOOKING CODE */}
        <div className='form-control'>
          <label htmlFor='code'>Booking Code</label>
          <input type='text' id='code' name='code' onChange={handleChange} />
        </div>

        {/* BOOKING LINK */}
        <div className='form-control'>
          <label htmlFor='link'>Booking Link</label>
          <input type='text' id='link' name='link' onChange={handleChange} />
        </div>

        <button
          className='primary'
          disabled={bookie.code && bookie.link && bookie.name ? false : true}
          onClick={() => {
            setBookies([...bookies, bookie]);
            setAddBookie(false);
            resetForm();
          }}
        >
          Add Bookie
        </button>
      </div>
    </div>
  );
};

export const EditMatchModal = ({ resetFunc, matchId, tipId, refreshFunc }) => {
  const [status, setStatus] = useState(`pending`);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [loading, setLoading] = useState(false);

  const updateStatus = async () => {
    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/free-accumulator-tips/update-match-status/${tipId}`,
        { matchId: matchId, status: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      resetFunc(``);
      refreshFunc();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              resetFunc(``);
            }}
          />
        </div>

        {/* BOOKIE */}
        <div className='form-control'>
          <label htmlFor='status'>Match Status</label>
          <select
            name='status'
            id='status'
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option defaultChecked hidden>
              Select status
            </option>
            <option value='pending' defaultValue={`pending`}>
              Pending
            </option>
            <option value='won'>Won</option>
            <option value='lost'>Lost</option>
          </select>
        </div>

        <div className='btn'>
          <button className='primary' onClick={() => updateStatus()}>
            Update Match {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFreeAccaTip;
