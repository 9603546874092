import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { AiTwotoneEdit } from 'react-icons/ai';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

const OverOnePointFiveTips = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getOverOnePointFiveTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/over-1.5-tips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setTips(data.tips);
    } catch (error) {
      setLoading(false);
    }
  };

  // DELETE TICKET
  const deleteTicket = async (id) => {
    try {
      // eslint-disable-next-line
      const { data } = await axios.delete(`${baseURL}/over-1.5-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Tip Deleted Successfully`);
      getOverOnePointFiveTips();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getOverOnePointFiveTips();
    // eslint-disable-next-line
  }, []);

  const searchByTeams = () => {
    const tempArray = tips.filter(
      (tip) =>
        tip.homeTeam.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        tip.awayTeam.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setTips(tempArray);
    if (searchTerm === ``) {
      getOverOnePointFiveTips();
    }
  };

  useEffect(() => {
    searchByTeams();
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <main className='over-one-point-five-tips-page'>
      <Navbar category={`Menu >`} currentPage={`Over 1.5 Tips`} />
      <Sidebar activePage={3} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        >
          <button
            className='primary'
            onClick={() => navigate(`/over-1.5-tips/new-tip`)}
          >
            Create Tip
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Teams</p>
            <p>League</p>
            <p>Status</p>
            <p>Match Time</p>
            <p>Action</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {tips &&
                tips.map((tip) => {
                  const { _id, matchTime, homeTeam, awayTeam, status, league } =
                    tip;
                  return (
                    <div className='tip-info' key={_id}>
                      <Link className='teams'>
                        {homeTeam} vs {awayTeam}
                      </Link>
                      <Link className='league'>{league}</Link>
                      <Link className='status'>{status}</Link>
                      <Link className='time'>
                        {moment(matchTime).format(`lll`)}
                      </Link>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() => navigate(`/over-1.5-tips/${_id}`)}
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => deleteTicket(_id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default OverOnePointFiveTips;
