import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';
import { toast } from 'react-toastify';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import { RiDeleteBin2Fill } from 'react-icons/ri';

const NewExpertSingle = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [prediction, setPrediction] = useState(``);
  const [addBookie, setAddBookie] = useState(false);
  const [bookies, setBookies] = useState([]);

  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      homeTeamAbrv: ``,
      homeTeamLogo: ``,
      homeTeamPrevMatches: ``,
      awayTeam: ``,
      awayTeamAbrv: ``,
      awayTeamLogo: ``,
      awayTeamPrevMatches: ``,
      matchTime: ``,
      competitionLogo: ``,
      competitionName: ``,
    },
    validationSchema: Yup.object({
      homeTeam: Yup.string().required(`Please provide home team`),
      homeTeamAbrv: Yup.string().required(
        `Please provide home team abbreviation`
      ),
      awayTeam: Yup.string().required(`Please provide away team`),
      awayTeamAbrv: Yup.string().required(
        `Please provide away team abbreviation`
      ),
      matchTime: Yup.string().required(`Please provide match time`),
      homeTeamLogo: Yup.string().required(`Please provide home logo`),
      awayTeamLogo: Yup.string().required(`Please provide away logo`),
      competitionLogo: Yup.string().required(`Please provide competition logo`),
      competitionName: Yup.string().required(`Please provide competition name`),
      homeTeamPrevMatches: Yup.string()
        .required(`Please provide stats`)
        .max(5, `Last five matches stats`)
        .matches(/^[wlWLDd]*$/, 'Input can only contain "w" or "l" characters'),
      awayTeamPrevMatches: Yup.string()
        .required(`Please provide stats`)
        .max(5, `Last five matches stats`)
        .matches(/^[wlWLDd]*$/, 'Input can only contain "w" or "l" characters'),
    }),
    onSubmit() {
      console.log(formik.values);
      createTip();
    },
  });

  const createTip = async () => {
    const { awayTeamPrevMatches, homeTeamPrevMatches } = formik.values;

    try {
      setLoading(true);
      // eslint-disable-next-line
      const { data } = await axios.post(
        `${baseURL}/expert-singles-tips`,
        {
          ...formik.values,
          homeTeamPrevMatches: homeTeamPrevMatches.toLowerCase().split(``),
          awayTeamPrevMatches: awayTeamPrevMatches.toLowerCase().split(``),
          prediction: prediction,
          bookies: bookies,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/expert-singles`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  const fetchLogos = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/clubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogos(data.clubs);
    } catch (error) {}
  };

  const fetchCompetitons = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompetitions(data.competitions);
    } catch (error) {}
  };

  const handleBookieDelete = (id) => {
    setBookies(() => bookies.filter((_, index) => index !== id));
  };

  useEffect(() => {
    fetchLogos();
    fetchCompetitons();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='new-expert-single-page'>
      <Navbar
        category={`Menu > Expert Singles >`}
        categoryLink={`/expert-singles`}
        currentPage={`New Expert Single`}
      />
      <Sidebar activePage={1} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/expert-singles`)} />
          <h2 className='title'>New Expert Single</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='ticket-details'>
          <h3 className='title'>Ticket Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* HOME TEAM */}
            <div className='form-control'>
              <label
                htmlFor='homeTeam'
                className={
                  formik.errors.homeTeam && formik.touched.homeTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeam && formik.touched.homeTeam
                  ? formik.errors.homeTeam
                  : `Home Team`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='homeTeam'
                value={formik.values.homeTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='homeTeam'
              />
            </div>

            {/* HOME TEAM ABBREVIATION */}
            <div className='form-control'>
              <label
                htmlFor='homeTeamAbrv'
                className={
                  formik.errors.homeTeamAbrv && formik.touched.homeTeamAbrv
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeamAbrv && formik.touched.homeTeamAbrv
                  ? formik.errors.homeTeamAbrv
                  : `Home Team Abbreviation`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='homeTeamAbrv'
                value={formik.values.homeTeamAbrv}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='homeTeamAbrv'
              />
            </div>

            {/* HOME TEAM LOGO */}
            <div className='form-control'>
              <label
                htmlFor='homeTeamLogo'
                className={
                  formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                  ? formik.errors.homeTeamLogo
                  : `Home Team Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='homeTeamLogo'
                id='homeTeamLogo'
                value={formik.values.homeTeamLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {logos.map((logo) => {
                  const { _id, clubName, clubLogo } = logo;
                  return (
                    <option
                      value={`https://api.kmanalysis.com${clubLogo}`}
                      key={_id}
                    >
                      {clubName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* HOME TEAM PREV MATCHES STATS */}
            <div className='form-control'>
              <label
                htmlFor='homeTeamPrevMatches'
                className={
                  formik.errors.homeTeamPrevMatches &&
                  formik.touched.homeTeamPrevMatches
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeamPrevMatches &&
                formik.touched.homeTeamPrevMatches
                  ? formik.errors.homeTeamPrevMatches
                  : `Home Team Previous Matches Stats`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='homeTeamPrevMatches'
                value={formik.values.homeTeamPrevMatches}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='homeTeamPrevMatches'
                placeholder='WLLLD'
              />
            </div>

            {/* AWAY TEAM */}
            <div className='form-control'>
              <label
                htmlFor='awayTeam'
                className={
                  formik.errors.awayTeam && formik.touched.awayTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeam && formik.touched.awayTeam
                  ? formik.errors.awayTeam
                  : `Away Team`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='awayTeam'
                value={formik.values.awayTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='awayTeam'
              />
            </div>

            {/* AWAY TEAM ABBREVIATION */}
            <div className='form-control'>
              <label
                htmlFor='awayTeamAbrv'
                className={
                  formik.errors.awayTeamAbrv && formik.touched.awayTeamAbrv
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeamAbrv && formik.touched.awayTeamAbrv
                  ? formik.errors.awayTeamAbrv
                  : `Away Team Abbreviation`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='awayTeamAbrv'
                value={formik.values.awayTeamAbrv}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='awayTeamAbrv'
              />
            </div>

            {/* AWAY TEAM LOGO */}
            <div className='form-control'>
              <label
                htmlFor='awayTeamLogo'
                className={
                  formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                  ? formik.errors.awayTeamLogo
                  : `Away Team Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='awayTeamLogo'
                id='awayTeamLogo'
                value={formik.values.awayTeamLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {logos.map((logo) => {
                  const { _id, clubName, clubLogo } = logo;
                  return (
                    <option
                      value={`https://api.kmanalysis.com${clubLogo}`}
                      key={_id}
                    >
                      {clubName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* AWAY TEAM PREV MATCHES STATS */}
            <div className='form-control'>
              <label
                htmlFor='awayTeamPrevMatches'
                className={
                  formik.errors.awayTeamPrevMatches &&
                  formik.touched.awayTeamPrevMatches
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeamPrevMatches &&
                formik.touched.awayTeamPrevMatches
                  ? formik.errors.awayTeamPrevMatches
                  : `Away Team Previous Matches Stats`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='awayTeamPrevMatches'
                value={formik.values.awayTeamPrevMatches}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='awayTeamPrevMatches'
                placeholder='WLLLD'
              />
            </div>

            {/* COMPETITION NAME */}
            <div className='form-control'>
              <label
                htmlFor='competitionName'
                className={
                  formik.errors.competitionName &&
                  formik.touched.competitionName
                    ? `error`
                    : ``
                }
              >
                {formik.errors.competitionName && formik.touched.competitionName
                  ? formik.errors.competitionName
                  : `Competition Name`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='competitionName'
                value={formik.values.competitionName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='competitionName'
              />
            </div>

            {/* COMPETITION LOGO */}
            <div className='form-control'>
              <label
                htmlFor='competitionLogo'
                className={
                  formik.errors.competitionLogo &&
                  formik.touched.competitionLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.competitionLogo && formik.touched.competitionLogo
                  ? formik.errors.competitionLogo
                  : `Competition Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='competitionLogo'
                id='competitionLogo'
                value={formik.values.competitionLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {competitions.map((logo) => {
                  const { _id, competitionName, competitionLogo } = logo;
                  return (
                    <option
                      value={`https://api.kmanalysis.com${competitionLogo}`}
                      key={_id}
                    >
                      {competitionName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* MATCH PREDICTION */}
            <div className='form-control'>
              <label htmlFor='image'>
                Match Analysis
                <span>*</span>
              </label>
              <CKEditor
                data='<p>Initial content</p>'
                onChange={(event) => setPrediction(event.editor.getData())}
              />
            </div>

            {/* MATCH TIME */}
            <div className='form-control'>
              <label
                htmlFor='matchTime'
                className={
                  formik.errors.matchTime && formik.touched.matchTime
                    ? `error`
                    : ``
                }
              >
                {formik.errors.matchTime && formik.touched.matchTime
                  ? formik.errors.matchTime
                  : `Match Time`}{' '}
                <span>*</span>
              </label>
              <input
                name='matchTime'
                id='matchTime'
                type='datetime-local'
                value={formik.values.matchTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* BOOKIES */}
            <div className='bookies'>
              <h3 className='title'>Bookies</h3>
              {bookies && (
                <>
                  {bookies.map((bookie, bookieIndex) => {
                    const { link, code, name } = bookie;
                    return (
                      <div className='bookie' key={bookieIndex}>
                        <div className='name'>
                          <h5>{name}</h5>
                        </div>
                        <div className='code'>
                          <h5>{code}</h5>
                        </div>
                        <div className='link'>
                          <h5>{link}</h5>
                        </div>
                        <div className='del'>
                          <RiDeleteBin2Fill
                            onClick={() => handleBookieDelete(bookieIndex)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className='add-bookie'>
              <button
                type='button'
                className='transparent'
                onClick={() => setAddBookie(true)}
              >
                Add Bookie
              </button>
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>
      {addBookie && (
        <AddBookieModal
          bookies={bookies}
          setBookies={setBookies}
          setAddBookie={setAddBookie}
        />
      )}
    </main>
  );
};

export const AddBookieModal = ({ bookies, setBookies, setAddBookie }) => {
  const [bookie, setBookie] = useState({
    name: ``,
    link: ``,
    code: ``,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBookie({ ...bookie, [name]: value });
  };

  const resetForm = () => {
    setBookie({
      name: ``,
      link: ``,
      code: ``,
    });
  };

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setAddBookie(false);
              resetForm();
            }}
          />
        </div>

        {/* BOOKIE */}
        <div className='form-control'>
          <label htmlFor='name'>Bookie</label>
          <select name='name' id='name' onChange={handleChange}>
            <option defaultChecked hidden>
              Select Bookie
            </option>
            <option value='1xbet'>1xbet</option>
            <option value='zebet'>Zebet</option>
            <option value='paripesa'>Paripesa</option>
            <option value='betwinner'>Betwinner</option>
            <option value='betano'>Betano</option>
            <option value='sportybet'>Sportybet</option>
            <option value='bet9ja'>Bet9ja</option>
            <option value='22bet'>22Bet</option>
          </select>
        </div>

        {/* BOOKING CODE */}
        <div className='form-control'>
          <label htmlFor='code'>Booking Code</label>
          <input type='text' id='code' name='code' onChange={handleChange} />
        </div>

        {/* BOOKING LINK */}
        <div className='form-control'>
          <label htmlFor='link'>Booking Link</label>
          <input type='text' id='link' name='link' onChange={handleChange} />
        </div>

        <button
          className='primary'
          disabled={bookie.code && bookie.link && bookie.name ? false : true}
          onClick={() => {
            setBookies([...bookies, bookie]);
            setAddBookie(false);
            resetForm();
          }}
        >
          Add Bookie
        </button>
      </div>
    </div>
  );
};

export default NewExpertSingle;
