import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';

const ViewMessage = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [specificMsg, setSpecificMsg] = useState({});
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  const getMessage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/contact-us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setSpecificMsg(data.message);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMessage();
    // eslint-disable-next-line
  }, []);

  console.log(specificMsg);

  return (
    <main className='messages-page'>
      <Navbar
        category={`Management > Messages >`}
        categoryLink={`/messages`}
        currentPage={`Reply Message`}
      />
      <Sidebar activePage={7} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <h3 className='title'>Reply Message</h3>
        <div className='message-box'>
          {loading ? (
            <Loader />
          ) : (
            <>
              <h2 className='subject'>{specificMsg.subject}</h2>
              <h4 className='name'>{`${specificMsg.name}`}</h4>
              <p className='email'>{specificMsg.email}</p>
              <p className='phone'>{specificMsg.phone}</p>
              <p className='date'>
                {moment(specificMsg.createdAt).format(`LLL`)}
              </p>
              <div className='message-body'>{specificMsg.message}</div>
              <a
                href={`mailto:${specificMsg.email}?subject=Reply to "Subject"`}
              >
                <button className='primary'>Reply</button>
              </a>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default ViewMessage;
