import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { DeleteAllBlogPosts } from '../../components/Modals/Modals';
import moment from 'moment/moment';
import ReactPaginate from 'react-paginate';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const NewsUpdates = () => {
  const { showProfileModal, baseURL, deleteBlogPosts, setDeleteBlogPosts } =
    useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getAllBlogPosts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/news?page=${pageNum}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setBlogPosts(data.news);
      setNewsCount(data.count);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogPosts();
    // eslint-disable-next-line
  }, [deleteBlogPosts, pageNum]);

  // SEARCH
  const searchNewPosts = () => {
    const tempArray = blogPosts.filter((post) =>
      post.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setBlogPosts(tempArray);
    if (searchTerm === ``) {
      getAllBlogPosts();
    }
  };

  useEffect(() => {
    searchNewPosts();
    // eslint-disable-next-line
  }, [searchTerm]);

  const itemsPerPage = 9;
  const pageCount = Math.ceil(newsCount / itemsPerPage);
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
  };

  return (
    <main className='news-updates-page'>
      <Navbar category={`Menu >`} currentPage={`News Updates`} />
      <Sidebar activePage={8} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          searchPlaceholder={`Post title`}
        >
          <button
            className='primary'
            onClick={() => navigate(`/news-updates/new-post`)}
          >
            New Post
          </button>
          <button className='red' onClick={() => setDeleteBlogPosts(true)}>
            Delete All
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Post Title</p>
            <p className='date-posted'>Date Posted</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {blogPosts &&
                blogPosts.map((post) => {
                  const { _id, title, createdAt } = post;

                  return (
                    <Link
                      to={`/news-updates/${_id}`}
                      className='post-info'
                      key={_id}
                    >
                      <p className='title'>{title}</p>
                      <p className='date-posted'>
                        {moment(createdAt).format(`MMM Do YY`)}
                      </p>
                    </Link>
                  );
                })}
            </>
          )}
        </div>

        <ReactPaginate
          breakLabel='...'
          nextLabel={<BsArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<BsArrowLeft />}
          renderOnZeroPageCount={null}
          containerClassName='pagination'
          pageLinkClassName='page-num'
          activeLinkClassName='active'
          previousClassName='btn back'
          nextClassName='btn forward'
        />
      </div>
      {deleteBlogPosts && <DeleteAllBlogPosts />}
    </main>
  );
};

export default NewsUpdates;
