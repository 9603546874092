import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';

const EditOverTwoPointFiveTip = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const fetchTicketDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/over-2.5-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTicketDetails(data.tip);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
    //eslint-disable-next-line
  }, [id]);

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      awayTeam: ``,
      league: ``,
      status: ``,
      matchTime: ``,
      result: ``,
    },
    onSubmit() {
      updateTip();
    },
  });

  const updateTip = async () => {
    const { awayTeam, homeTeam, league, status, matchTime, result } =
      formik.values;
    const formData = new FormData();
    if (homeTeam) {
      formData.append(`homeTeam`, homeTeam);
    }
    if (awayTeam) {
      formData.append(`awayTeam`, awayTeam);
    }
    if (league) {
      formData.append(`league`, league);
    }
    if (status) {
      formData.append(`status`, status);
    }
    if (matchTime) {
      formData.append(`matchTime`, matchTime);
    }
    if (result) {
      formData.append(`result`, result);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/over-2.5-tips/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/over-2.5-tips`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { matchTime, homeTeam, awayTeam, status, league, result } =
    ticketDetails;

  return (
    <main className='edit-over-two-point-five-tip'>
      <Navbar
        category={`Management > Over 2.5 Tips >`}
        categoryLink={`/over-2.5-tips`}
        currentPage={`Edit Tip`}
      />
      <Sidebar activePage={4} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/over-2.5-tips`)} />
          <h2 className='title'>Edit Tip</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* TICKET INFORMATION BOX */}
            <div className='ticket-details'>
              <h3 className='title'>Tip Details</h3>

              <form action='' onSubmit={formik.handleSubmit}>
                {/* HOME TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeam'
                    className={
                      formik.errors.homeTeam && formik.touched.homeTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeam && formik.touched.homeTeam
                      ? formik.errors.homeTeam
                      : `Home Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='homeTeam'
                    id='homeTeam'
                    value={formik.values.homeTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={homeTeam}
                  />
                </div>

                {/* AWAY TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeam'
                    className={
                      formik.errors.awayTeam && formik.touched.awayTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeam && formik.touched.awayTeam
                      ? formik.errors.awayTeam
                      : `Away Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='awayTeam'
                    id='awayTeam'
                    value={formik.values.awayTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={awayTeam}
                  />
                </div>

                {/* LEAGUE */}
                <div className='form-control'>
                  <label
                    htmlFor='league'
                    className={
                      formik.errors.league && formik.touched.league
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.league && formik.touched.league
                      ? formik.errors.league
                      : `League Initials`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='league'
                    id='league'
                    value={formik.values.league}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={league}
                  />
                </div>

                {/* STATUS */}
                <div className='form-control'>
                  <label
                    htmlFor='status'
                    className={
                      formik.errors.status && formik.touched.status
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.status && formik.touched.status
                      ? formik.errors.status
                      : `Status`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='status'
                    id='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={status}
                  >
                    <option defaultChecked hidden>
                      Select Tip Status
                    </option>
                    <option value='pending'>Pending</option>
                    <option value='won'>Won</option>
                    <option value='lost'>Lost</option>
                  </select>
                </div>

                {/* RESULT */}
                <div className='form-control'>
                  <label
                    htmlFor='result'
                    className={
                      formik.errors.result && formik.touched.result
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.result && formik.touched.result
                      ? formik.errors.result
                      : `Match Result`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='result'
                    id='result'
                    value={formik.values.result}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={result}
                  />
                </div>

                {/* MATCH TIME */}
                <div className='form-control'>
                  <label
                    htmlFor='matchTime'
                    className={
                      formik.errors.matchTime && formik.touched.matchTime
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.matchTime && formik.touched.matchTime
                      ? formik.errors.matchTime
                      : `Match Time`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='matchTime'
                    id='matchTime'
                    type='datetime-local'
                    value={formik.values.matchTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={moment(matchTime).format(`HH:MM`)}
                  />
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update Tip {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default EditOverTwoPointFiveTip;
