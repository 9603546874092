import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';
import { CKEditor } from 'ckeditor4-react';
import { RiDeleteBin2Fill } from 'react-icons/ri';

const EditExpertSingle = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [logos, setLogos] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [newPrediction, setNewPrediction] = useState(``);

  const fetchTicketDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/expert-singles-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTicketDetails(data.tip);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      homeTeamAbrv: ``,
      awayTeam: ``,
      awayTeamAbrv: ``,
      matchTime: ``,
      homeTeamLogo: ``,
      awayTeamLogo: ``,
      homeTeamPrevMatches: ``,
      awayTeamPrevMatches: ``,
      competitionLogo: ``,
      competitionName: ``,
    },
    onSubmit() {
      updateTip();
    },
  });

  const fetchLogos = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/clubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogos(data.clubs);
    } catch (error) {}
  };

  const fetchCompetitons = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompetitions(data.competitions);
    } catch (error) {}
  };

  const updateTip = async () => {
    const {
      awayTeam,
      homeTeam,
      league,
      status,
      matchTime,
      awayTeamLogo,
      homeTeamLogo,
      homeTeamPrevMatches,
      awayTeamPrevMatches,
      competitionLogo,
      awayTeamAbrv,
      competitionName,
      homeTeamAbrv,
    } = formik.values;
    const formData = new FormData();
    if (homeTeam) {
      formData.append(`homeTeam`, homeTeam);
    }
    if (awayTeam) {
      formData.append(`awayTeam`, awayTeam);
    }
    if (league) {
      formData.append(`league`, league);
    }
    if (status) {
      formData.append(`status`, status);
    }
    if (matchTime) {
      formData.append(`matchTime`, matchTime);
    }
    if (homeTeamLogo) {
      formData.append(`homeTeamLogo`, homeTeamLogo);
    }
    if (awayTeamLogo) {
      formData.append(`awayTeamLogo`, awayTeamLogo);
    }
    if (homeTeamPrevMatches) {
      formData.append(`homeTeamPrevMatches`, homeTeamPrevMatches);
    }
    if (awayTeamPrevMatches) {
      formData.append(`awayTeamPrevMatches`, awayTeamPrevMatches);
    }
    if (competitionLogo) {
      formData.append(`competitionLogo`, competitionLogo);
    }
    if (newPrediction) {
      formData.append(`prediction`, newPrediction);
    }
    if (awayTeamAbrv) {
      formData.append(`awayTeamAbrv`, awayTeamAbrv);
    }
    if (homeTeamAbrv) {
      formData.append(`homeTeamAbrv`, homeTeamAbrv);
    }
    if (competitionName) {
      formData.append(`competitionName`, competitionName);
    }

    try {
      setLoading(true);

      const { data } = await axios.patch(
        `${baseURL}/expert-singles-tips/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/expert-singles`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const deleteBookie = async (bookieId) => {
    try {
      const { data } = await axios.patch(
        `${baseURL}/expert-singles-tips/delete-bookie/${bookieId}`,
        { tipId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      fetchTicketDetails();
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };
  useEffect(() => {
    fetchTicketDetails();
    fetchLogos();
    fetchCompetitons();
    //eslint-disable-next-line
  }, [id]);

  const {
    homeTeam,
    awayTeam,
    homeTeamAbrv,
    awayTeamAbrv,
    competitionName,
    bookies: oldBookies,
  } = ticketDetails;

  console.log(ticketDetails);

  return (
    <main className='edit-expert-singles-tip-page'>
      <Navbar
        category={`Management > Expert Sinlges >`}
        categoryLink={`/expert-singles`}
        currentPage={`Edit Tip`}
      />
      <Sidebar activePage={1} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/expert-singles`)} />
          <h2 className='title'>Edit Tip</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* TICKET INFORMATION BOX */}
            <div className='ticket-details'>
              <h3 className='title'>Tip Details</h3>

              <form action='' onSubmit={formik.handleSubmit}>
                {/* HOME TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeam'
                    className={
                      formik.errors.homeTeam && formik.touched.homeTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeam && formik.touched.homeTeam
                      ? formik.errors.homeTeam
                      : `Home Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='homeTeam'
                    value={formik.values.homeTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='homeTeam'
                    placeholder={homeTeam}
                  />
                </div>

                {/* HOME TEAM ABBREVIATION */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeamAbrv'
                    className={
                      formik.errors.homeTeamAbrv && formik.touched.homeTeamAbrv
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeamAbrv && formik.touched.homeTeamAbrv
                      ? formik.errors.homeTeamAbrv
                      : `Home Team Abbreviation`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='homeTeamAbrv'
                    value={formik.values.homeTeamAbrv}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='homeTeamAbrv'
                    placeholder={homeTeamAbrv.toUpperCase()}
                  />
                </div>

                {/* HOME TEAM LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeamLogo'
                    className={
                      formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                      ? formik.errors.homeTeamLogo
                      : `Home Team Logo`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='homeTeamLogo'
                    id='homeTeamLogo'
                    value={formik.values.homeTeamLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {logos.map((logo) => {
                      const { _id, clubName, clubLogo } = logo;
                      return (
                        <option
                          value={`https://api.kmanalysis.com${clubLogo}`}
                          key={_id}
                        >
                          {clubName} Logo
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* HOME TEAM PREV MATCHES STATS */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeamPrevMatches'
                    className={
                      formik.errors.homeTeamPrevMatches &&
                      formik.touched.homeTeamPrevMatches
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeamPrevMatches &&
                    formik.touched.homeTeamPrevMatches
                      ? formik.errors.homeTeamPrevMatches
                      : `Home Team Previous Matches Stats`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='homeTeamPrevMatches'
                    value={formik.values.homeTeamPrevMatches}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='homeTeamPrevMatches'
                    placeholder='WLLLD'
                  />
                </div>

                {/* AWAY TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeam'
                    className={
                      formik.errors.awayTeam && formik.touched.awayTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeam && formik.touched.awayTeam
                      ? formik.errors.awayTeam
                      : `Away Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='awayTeam'
                    value={formik.values.awayTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='awayTeam'
                    placeholder={awayTeam}
                  />
                </div>

                {/* AWAY TEAM ABBREVIATION */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeamAbrv'
                    className={
                      formik.errors.awayTeamAbrv && formik.touched.awayTeamAbrv
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeamAbrv && formik.touched.awayTeamAbrv
                      ? formik.errors.awayTeamAbrv
                      : `Away Team Abbreviation`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='awayTeamAbrv'
                    value={formik.values.awayTeamAbrv}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='awayTeamAbrv'
                    placeholder={awayTeamAbrv.toUpperCase()}
                  />
                </div>

                {/* AWAY TEAM LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeamLogo'
                    className={
                      formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                      ? formik.errors.awayTeamLogo
                      : `Away Team Logo`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='awayTeamLogo'
                    id='awayTeamLogo'
                    value={formik.values.awayTeamLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {logos.map((logo) => {
                      const { _id, clubName, clubLogo } = logo;
                      return (
                        <option
                          value={`https://api.kmanalysis.com${clubLogo}`}
                          key={_id}
                        >
                          {clubName} Logo
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* AWAY TEAM PREV MATCHES STATS */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeamPrevMatches'
                    className={
                      formik.errors.awayTeamPrevMatches &&
                      formik.touched.awayTeamPrevMatches
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeamPrevMatches &&
                    formik.touched.awayTeamPrevMatches
                      ? formik.errors.awayTeamPrevMatches
                      : `Away Team Previous Matches Stats`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='awayTeamPrevMatches'
                    value={formik.values.awayTeamPrevMatches}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='awayTeamPrevMatches'
                    placeholder='WLLLD'
                  />
                </div>

                {/* COMPETITION NAME */}
                <div className='form-control'>
                  <label
                    htmlFor='competitionName'
                    className={
                      formik.errors.competitionName &&
                      formik.touched.competitionName
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.competitionName &&
                    formik.touched.competitionName
                      ? formik.errors.competitionName
                      : `Competition Name`}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='competitionName'
                    value={formik.values.competitionName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='competitionName'
                    placeholder={competitionName}
                  />
                </div>

                {/* COMPETITION LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='competitionLogo'
                    className={
                      formik.errors.competitionLogo &&
                      formik.touched.competitionLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.competitionLogo &&
                    formik.touched.competitionLogo
                      ? formik.errors.competitionLogo
                      : `Competition`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='competitionLogo'
                    id='competitionLogo'
                    value={formik.values.competitionLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {competitions.map((logo) => {
                      const { _id, competitionName, competitionLogo } = logo;
                      return (
                        <option
                          value={`https://api.kmanalysis.com${competitionLogo}`}
                          key={_id}
                        >
                          {competitionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* MATCH PREDICTION */}
                <div className='form-control'>
                  <label htmlFor='image'>
                    Match Analysis
                    <span>*</span>
                  </label>
                  <CKEditor
                    data='<p>Initial content</p>'
                    onChange={(event) =>
                      setNewPrediction(event.editor.getData())
                    }
                  />
                </div>

                {/* MATCH TIME */}
                <div className='form-control'>
                  <label
                    htmlFor='matchTime'
                    className={
                      formik.errors.matchTime && formik.touched.matchTime
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.matchTime && formik.touched.matchTime
                      ? formik.errors.matchTime
                      : `Match Time`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='matchTime'
                    id='matchTime'
                    type='datetime-local'
                    value={formik.values.matchTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* BOOKIES */}
                <div className='bookies current'>
                  <h3 className='title'>Current Bookies</h3>
                  {oldBookies?.map((bookie) => {
                    const { _id, name, code, link } = bookie;
                    return (
                      <div className='bookie' key={_id}>
                        <div className='name'>
                          <h5>{name}</h5>
                        </div>
                        <div className='code'>{code}</div>
                        <div className='link'>
                          <h5>{link}</h5>
                        </div>
                        <div className='delete'>
                          <RiDeleteBin2Fill onClick={() => deleteBookie(_id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update Tip {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default EditExpertSingle;
