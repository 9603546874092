import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import { UpdateAd } from '../../components/Modals/Modals';

const Ads = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [adOne, setAdOne] = useState({});
  const [adTwo, setAdTwo] = useState({});
  const [loading, setLoading] = useState(true);
  const [updateAd, setUpdateAd] = useState(false);
  const [adId, setAdId] = useState(``);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  //   FETCH LINKS
  const fetchLinks = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/ads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAdOne(data[0]);
      setAdTwo(data[1]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line
  }, [updateAd]);

  return (
    <main className='ads-page'>
      <Navbar category={`Management >`} currentPage={`Ads`} />
      <Sidebar activePage={12} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Ad Links</h2>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* LINK 1 */}
            <div className='link-details'>
              <h3 className='title'>Ad Link 1 Details</h3>
              <div className='form-control'>
                <label htmlFor=''>Ad Link</label>
                <h3>{adOne.adLink}</h3>
              </div>
              <div className='form-control'>
                <label htmlFor=''>Ad Caption</label>
                <h3>{adOne.adCaption}</h3>
              </div>
              <div className='form-control'>
                <label htmlFor=''>Ad Image</label>
                <div className='img'>
                  <img
                    src={`https://api.kmanalysis.com${adOne.adImage}`}
                    alt=''
                  />
                </div>
              </div>
              <div className='btns'>
                <button
                  className='primary'
                  onClick={() => {
                    setAdId(adOne._id);
                    setUpdateAd(true);
                  }}
                >
                  Update Ad
                </button>
              </div>
            </div>

            {/* LINK 2 */}
            <div className='link-details'>
              <h3 className='title'>Ad Link 2 Details</h3>
              <div className='form-control'>
                <label htmlFor=''>Ad Link</label>
                <h3>{adTwo.adLink}</h3>
              </div>
              <div className='form-control'>
                <label htmlFor=''>Ad Caption</label>
                <h3>{adTwo.adCaption}</h3>
              </div>
              <div className='form-control'>
                <label htmlFor=''>Ad Image</label>
                <div className='img'>
                  <img
                    src={`https://api.kmanalysis.com${adTwo.adImage}`}
                    alt=''
                  />
                </div>
              </div>
              <div className='btns'>
                <button
                  className='primary'
                  onClick={() => {
                    setAdId(adTwo._id);
                    setUpdateAd(true);
                  }}
                >
                  Update Ad
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {updateAd && <UpdateAd adId={adId} dismissFunc={setUpdateAd} />}
    </main>
  );
};

export default Ads;
