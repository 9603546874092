import { Slide, ToastContainer } from "react-toastify";
import {
  Admins,
  Ads,
  BttsggTips,
  Clubs,
  Competitions,
  Customers,
  Dashboard,
  DoubleChanceTips,
  EditBttsggTip,
  EditDoubleChanceTip,
  EditExpertSingle,
  EditFreeAccaTip,
  EditHandicapTip,
  EditOverOnePointFiveTip,
  EditOverTwoPointFiveTip,
  ExpertSingles,
  Extras,
  FreeAccumulatorTips,
  Gifs,
  HandicapTips,
  Login,
  Messages,
  NewAdmin,
  NewBttsggTip,
  NewDoubleChanceTip,
  NewExpertSingle,
  NewFreeAccaTip,
  NewHandicapTip,
  NewOverOnePointFiveTip,
  NewOverTwoPointFiveTip,
  NewPost,
  NewsUpdates,
  Offers,
  OverOnePointFiveTips,
  OverTwoPointFiveTips,
  Popups,
  SocialMediaLinks,
  ViewCustomer,
  ViewMessage,
  ViewPost,
  Send,
  Mails,
  ViewMail,
} from "./pages";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./pages/PrivateRoute";
import { useEffect } from "react";
import { useGlobalContext } from "./context/Context";

function App() {
  const { wakeServer } = useGlobalContext();

  useEffect(() => {
    wakeServer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/expert-singles" element={<ExpertSingles />} />
          <Route path="/expert-singles/new-tip" element={<NewExpertSingle />} />
          <Route path="/expert-singles/:id" element={<EditExpertSingle />} />
          <Route
            path="/free-accumulator-tips"
            element={<FreeAccumulatorTips />}
          />
          <Route
            path="/free-accumulator-tips/:id"
            element={<EditFreeAccaTip />}
          />
          <Route
            path="/free-accumulator-tips/new-tip"
            element={<NewFreeAccaTip />}
          />
          <Route path="/over-1.5-tips" element={<OverOnePointFiveTips />} />
          <Route
            path="/over-1.5-tips/new-tip"
            element={<NewOverOnePointFiveTip />}
          />
          <Route
            path="/over-1.5-tips/:id"
            element={<EditOverOnePointFiveTip />}
          />

          <Route path="/over-2.5-tips" element={<OverTwoPointFiveTips />} />
          <Route
            path="/over-2.5-tips/new-tip"
            element={<NewOverTwoPointFiveTip />}
          />
          <Route
            path="/over-2.5-tips/:id"
            element={<EditOverTwoPointFiveTip />}
          />

          <Route path="/bttsgg-tips" element={<BttsggTips />} />
          <Route path="/bttsgg-tips/new-tip" element={<NewBttsggTip />} />
          <Route path="/bttsgg-tips/:id" element={<EditBttsggTip />} />

          <Route path="/double-chance-tips" element={<DoubleChanceTips />} />
          <Route
            path="/double-chance-tips/new-tip"
            element={<NewDoubleChanceTip />}
          />
          <Route
            path="/double-chance-tips/:id"
            element={<EditDoubleChanceTip />}
          />

          <Route path="/handicap-tips" element={<HandicapTips />} />
          <Route path="/handicap-tips/new-tip" element={<NewHandicapTip />} />
          <Route path="/handicap-tips/:id" element={<EditHandicapTip />} />

          <Route path="/news-updates" element={<NewsUpdates />} />
          <Route path="/news-updates/:id" element={<ViewPost />} />
          <Route path="/news-updates/new-post" element={<NewPost />} />

          <Route path="/emails" element={<Mails />} />
          <Route path="/emails/:id" element={<ViewMail />} />
          <Route path="/emails/send" element={<Send />} />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:id" element={<ViewCustomer />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/social-media-links" element={<SocialMediaLinks />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/admins/new-admin" element={<NewAdmin />} />
          <Route path="/admins/:id" element={<Admins />} />
          <Route path="/extras" element={<Extras />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:id" element={<ViewMessage />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/pop-ups" element={<Popups />} />
          <Route path="/gifs" element={<Gifs />} />
        </Route>
      </Routes>
      <ToastContainer transition={Slide} autoClose={1000} />
    </>
  );
}

export default App;
