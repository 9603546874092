import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { AiTwotoneEdit } from 'react-icons/ai';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { DeleteAllFreeAccaTiipsModal } from '../../components/Modals/Modals';

const FreeAccumulatorTips = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [freeAccaTips, setFreeAccaTips] = useState([]);
  const [deleteTips, setDeleteTips] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getFreeAccumulatorTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/free-accumulator-tips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setFreeAccaTips(data.tips);
    } catch (error) {
      setLoading(false);
    }
  };

  // DELETE TICKET
  const deleteTicket = async (id) => {
    try {
      // eslint-disable-next-line
      const { data } = await axios.delete(
        `${baseURL}/free-accumulator-tips/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Tip Deleted Successfully`);
      getFreeAccumulatorTips();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getFreeAccumulatorTips();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='free-acca-tips-page'>
      <Navbar category={`Menu >`} currentPage={`Free Accumulator Tips`} />
      <Sidebar activePage={2} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        >
          <button
            className='primary'
            onClick={() => navigate(`/free-accumulator-tips/new-tip`)}
          >
            Create Tip
          </button>
          <button className='red' onClick={() => setDeleteTips(true)}>
            Delete All
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Total Odds</p>
            <p>No. of Bookies</p>
            <p>Match Time</p>
            <p>Action</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {freeAccaTips &&
                freeAccaTips.map((tip) => {
                  const { _id, bookies, odds, matchTime } = tip;

                  return (
                    <div className='tip-info' key={_id}>
                      <Link className='no-of-matches'>{odds}</Link>
                      <Link className='no-of-bookies'>{bookies.length}</Link>
                      <Link className='no-of-matches'>
                        {moment(matchTime).format(`lll`)}
                      </Link>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() =>
                            navigate(`/free-accumulator-tips/${_id}`)
                          }
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => deleteTicket(_id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
      {deleteTips && (
        <DeleteAllFreeAccaTiipsModal
          dismissFunc={setDeleteTips}
          refreshFunc={getFreeAccumulatorTips}
        />
      )}
    </main>
  );
};

export default FreeAccumulatorTips;
