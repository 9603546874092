import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import FunctionsBar from "../../components/Functions-Bar/FunctionsBar";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/Context";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import { DeleteAllMails } from "../../components/Modals/Modals";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const STATUSES = [
  { title: "DRAFT", color: "info" },
  { title: "QUEUED FOR SENDING", color: "success" },
  { title: "SENT", color: "success" },
  { title: "FAILED", color: "danger" },
  { title: "SENDING", color: "info" },
];
const Mails = () => {
  const { showProfileModal, baseURL, deleteMails, setDeleteMails } =
    useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [mails, setMails] = useState([]);
  const [mailsCount, setMailsCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getAllMails = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/mails?page=${pageNum}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setMails(data.mails);
      setMailsCount(data.count);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllMails();
    // eslint-disable-next-line
  }, [deleteMails, pageNum]);

  // SEARCH
  const searchNewPosts = () => {
    const tempArray = mails.filter((post) =>
      post.subject.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setMails(tempArray);
    if (searchTerm === ``) {
      getAllMails();
    }
  };

  useEffect(() => {
    searchNewPosts();
    // eslint-disable-next-line
  }, [searchTerm]);

  const itemsPerPage = 9;
  const pageCount = Math.ceil(mailsCount / itemsPerPage);
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
  };

  return (
    <main className="mails-page">
      <Navbar category={`Menu >`} currentPage={`Emails`} />
      <Sidebar activePage={20} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          searchPlaceholder={`Mail subject`}
        >
          <button className="primary" onClick={() => navigate(`/emails/send`)}>
            New Mail
          </button>
          <button className="red" onClick={() => setDeleteMails(true)}>
            Delete All
          </button>
        </FunctionsBar>
        <div className="table">
          <div className="headers">
            <p>Recipient</p>
            <p>Subject</p>
            <p>Status</p>
            <p>Sent / Total</p>
            <p className="date-posted">Date Added</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {mails &&
                mails.map((post) => {
                  const {
                    _id,
                    recipients,
                    usertype,
                    subject,
                    status,
                    createdAt,
                  } = post;
                  const numSent = parseInt(post.numSent || 0, 10);
                  const total = parseInt(post.total || 0, 10);
                  return (
                    <Link to={`/emails/${_id}`} className="post-info" key={_id}>
                      <p className="title">
                        {(parseInt(usertype, 10) === 0 && "ALL CUSTOMERS") ||
                          recipients}
                      </p>
                      <p className="title">{subject}</p>
                      <p
                        className={`title ${
                          STATUSES[parseInt(status, 10)].color
                        }`}
                      >
                        {STATUSES[parseInt(status, 10)].title}
                      </p>
                      <p className={`title`}>
                        {numSent} / {total}
                      </p>
                      <p className="date-posted">
                        {moment(createdAt).format(`MMM Do YY`)}
                      </p>
                    </Link>
                  );
                })}
            </>
          )}
        </div>

        <ReactPaginate
          breakLabel="..."
          nextLabel={<BsArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<BsArrowLeft />}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          activeLinkClassName="active"
          previousClassName="btn back"
          nextClassName="btn forward"
        />
      </div>
      {deleteMails && <DeleteAllMails />}
    </main>
  );
};

export default Mails;
