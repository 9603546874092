import React, { useState } from "react";
import { useGlobalContext } from "../../context/Context";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";

// COMPONENTS
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";

// ASSETS
import spinner from "../../assets/purple-btn-spinner.svg";
import { toast } from "react-toastify";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";

const Send = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();
  const [postContent, setPostContent] = useState(``);

  const formik = useFormik({
    initialValues: {
      subject: ``,
      usertype: `0`,
      message: "",
      recipients: "",
      status: "0",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(`Subject is required`),
      usertype: Yup.string().required(`Recipient is required`),
    }),
    onSubmit() {
      sendMail();
    },
  });

  const sendMail = async () => {
    const formData = new FormData();
    formData.append(`recipients`, formik.values.recipients);
    formData.append(`usertype`, formik.values.usertype);
    formData.append(`subject`, formik.values.subject);
    formData.append(`status`, formik.values.status);
    formData.append(`message`, postContent);

    try {
      setLoading(true);
      // eslint-disable-next-line
      await axios.post(`${baseURL}/mails`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Mail Created`);
      navigate(`/emails`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };
  return (
    <main className="new-post-page">
      <Navbar
        category={`Menu > Emails >`}
        categoryLink={`/email`}
        currentPage={`Send Mail`}
      />
      <Sidebar activePage={20} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className="post-btn">
          <FaChevronLeft onClick={() => navigate(`/vip-tips`)} />
          <h2 className="title">New Mail</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className="post-details">
          <form action="" onSubmit={formik.handleSubmit}>
            {/* USERTYPE */}
            <div className="form-control">
              <label
                htmlFor="usertype"
                className={
                  formik.errors.usertype && formik.touched.usertype
                    ? `error`
                    : ``
                }
              >
                {formik.errors.usertype && formik.touched.usertype
                  ? formik.errors.usertype
                  : `Select Recipients`}{" "}
                <span>*</span>
              </label>
              <select
                type="text"
                id="usertype"
                value={formik.values.usertype}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="usertype"
              >
                <option value="0">All Customers</option>
                <option value="1">Others</option>
              </select>
            </div>

            {/* RECIPIENTS */}
            {formik.values.usertype === "1" && (
              <div className="form-control">
                <label
                  htmlFor="recipients"
                  className={
                    formik.errors.recipients && formik.touched.recipients
                      ? `error`
                      : ``
                  }
                >
                  {formik.errors.recipients && formik.touched.recipients
                    ? formik.errors.recipients
                    : `Recipients`}{" "}
                  <span>*</span>
                </label>
                <textarea
                  type="text"
                  id="recipients"
                  value={formik.values.recipients}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="recipients"
                  placeholder="Please enter the email addresses separated by comma eg: test@test.com,demo@demo.com"
                  rows={10}
                />
              </div>
            )}

            {/* SUBJECT */}
            <div className="form-control">
              <label
                htmlFor="subject"
                className={
                  formik.errors.subject && formik.touched.subject ? `error` : ``
                }
              >
                {formik.errors.subject && formik.touched.subject
                  ? formik.errors.subject
                  : `Subject`}{" "}
                <span>*</span>
              </label>
              <input
                type="text"
                id="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="subject"
              />
            </div>

            {/* MAIL BODY */}
            <div className="form-control">
              <label htmlFor="image">
                Body
                <span>*</span>
              </label>
              <CKEditor
                data="<p>Initial content</p>"
                onChange={(event) => setPostContent(event.editor.getData())}
                editorUrl="https://crons.kmanalysis.com/plugins/ckeditor/ckeditor.js"
              />
            </div>

            {/* USERTYPE */}
            <div className="form-control">
              <label
                htmlFor="status"
                className={
                  formik.errors.status && formik.touched.status ? `error` : ``
                }
              >
                {formik.errors.status && formik.touched.status
                  ? formik.errors.status
                  : `Status`}{" "}
                <span>*</span>
              </label>
              <select
                type="text"
                id="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="status"
              >
                <option value="1">Send Now</option>
                <option value="0">Send Later</option>
              </select>
            </div>

            {/* SUBMIT BUTTON */}
            <button type="submit" className="primary">
              Create {loading ? <img src={spinner} alt="" /> : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Send;
