import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import spinner from '../../assets/purple-btn-spinner.svg';

const Extras = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [predict4Me, setPredict4Me] = useState({});
  const [liveChat, setLiveChat] = useState({});
  const [genLoading, setGenLoading] = useState(``);
  const [newPredict4MeLink, setNewPredict4MeLink] = useState(``);
  const [newLiveChatLink, setNewLiveChatLink] = useState(``);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  const fetchPredict4MeLink = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/predict-4-me`);
      setLoading(false);
      setPredict4Me(data);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const fetchLiveChatLink = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/live-chat`);
      setLoading(false);
      setLiveChat(data);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  // UPDATE PREDICT 4 ME LINK
  const updatePredict4MeLink = async () => {
    try {
      setGenLoading(`predict-4-me loading`);
      await axios.patch(
        `${baseURL}/predict-4-me/${predict4Me._id}`,
        { link: newPredict4MeLink },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Link Updated`);
      setGenLoading(``);
      setNewPredict4MeLink(``);
      fetchPredict4MeLink();
    } catch (error) {
      setPasswordLoading(false);
      setGenLoading(``);
      toast.error(error.response.data.msg);
    }
  };

  // UPDATE LIVE CHAT LINK
  const updateLiveChatLink = async () => {
    try {
      setGenLoading(`live chat loading`);
      await axios.patch(
        `${baseURL}/live-chat/${liveChat._id}`,
        { link: newLiveChatLink },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Link Updated`);
      setGenLoading(``);
      setNewPredict4MeLink(``);
      fetchPredict4MeLink();
    } catch (error) {
      setPasswordLoading(false);
      setGenLoading(``);
      toast.error(error.response.data.msg);
    }
  };

  // UPDATE ADMIN PASSWORD
  const formikPassword = useFormik({
    initialValues: {
      password: ``,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required(`Password is required`),
    }),
    onSubmit() {
      updatePassword();
    },
  });

  const updatePassword = async () => {
    try {
      setPasswordLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/auth/admin/update-password`,
        formikPassword.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Password Updated`);
      setPasswordLoading(false);
      console.log(data);
    } catch (error) {
      setPasswordLoading(false);
      console.log(error.response.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchPredict4MeLink();
    fetchLiveChatLink();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='extras-page'>
      <Navbar category={`Management >`} currentPage={`Extras`} />
      <Sidebar activePage={19} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Extras</h2>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* PREDICT FOR ME LINK */}
            <div className='link-details'>
              <form action='' onSubmit={handleSubmit}>
                <h3 className='title'>Predict 4 me🔥</h3>
                <div className='form-control'>
                  <label htmlFor=''>Link</label>
                  <input
                    type='text'
                    name='predictLink'
                    id='predictLink'
                    placeholder={predict4Me ? predict4Me.link : `Loading...`}
                    value={newPredict4MeLink}
                    onChange={(e) => setNewPredict4MeLink(e.target.value)}
                  />
                </div>
                <div className='btns'>
                  <button
                    className='primary'
                    disabled={!newPredict4MeLink && true}
                    type='submit'
                    onClick={() => {
                      updatePredict4MeLink();
                    }}
                  >
                    Update Link{' '}
                    {genLoading === `predict-4-me loading` ? (
                      <img src={spinner} alt='' />
                    ) : null}
                  </button>
                </div>
              </form>
            </div>

            {/* LIVE CHAT LINK */}
            <div className='link-details'>
              <form action='' onSubmit={handleSubmit}>
                <h3 className='title'>Live Chat Link</h3>
                <div className='form-control'>
                  <label htmlFor=''>Link</label>
                  <input
                    type='text'
                    name='predictLink'
                    id='predictLink'
                    placeholder={liveChat ? liveChat.link : `Loading...`}
                    value={newLiveChatLink}
                    onChange={(e) => setNewLiveChatLink(e.target.value)}
                  />
                </div>
                <div className='btns'>
                  <button
                    className='primary'
                    disabled={!newLiveChatLink && true}
                    type='submit'
                    onClick={() => {
                      updateLiveChatLink();
                    }}
                  >
                    Update Link{' '}
                    {genLoading === `live chat loading` ? (
                      <img src={spinner} alt='' />
                    ) : null}
                  </button>
                </div>
              </form>
            </div>

            {/* UPDATE PASSWORD */}
            <div className='link-details'>
              <h3 className='title'>Update Password</h3>
              <form action='' onSubmit={formikPassword.handleSubmit}>
                <div className='form-control'>
                  <label
                    htmlFor='total-tips'
                    className={
                      formikPassword.touched.password &&
                      formikPassword.errors.password
                        ? `error`
                        : ``
                    }
                  >
                    {formikPassword.errors.password &&
                    formikPassword.touched.password
                      ? formikPassword.errors.password
                      : `New Password`}
                  </label>
                  <div className='password-container'>
                    <input
                      type='password'
                      id='password'
                      name='password'
                      value={formikPassword.values.password}
                      onChange={formikPassword.handleChange}
                      onBlur={formikPassword.handleBlur}
                    />
                    {showPassword ? (
                      <FaEyeSlash
                        className='icon'
                        onClick={() => {
                          revealPassword(`password`);
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <FaEye
                        className='icon'
                        onClick={() => {
                          revealPassword(`password`);
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </div>
                </div>
                <button className='primary' type='submit'>
                  Update Password{' '}
                  {passwordLoading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Extras;
