import React from 'react';
import { FaBars, FaChevronDown } from 'react-icons/fa';
import { useGlobalContext } from '../../context/Context';
import ProfileModal from '../Profile-Modal/ProfileModal';
import { Link } from 'react-router-dom';

const Navbar = ({ category, categoryLink, currentPage }) => {
  const { setShowNavbar, showProfileModal, setShowProfileModal } =
    useGlobalContext();
  const { name } = JSON.parse(sessionStorage.getItem(`adminData`));
  const initials = name.split(` `).map((item) => item.split(``)[0]);

  return (
    <nav>
      <div className='mobile-nav'>
        <div className='menu-nav'>
          <FaBars className='hambugger' onClick={() => setShowNavbar(true)} />
          <h5>{currentPage}</h5>
        </div>
      </div>

      <div className='desktop-nav'>
        <div className='navigation'>
          <h5>
            <Link to={categoryLink}>{category}</Link>
          </h5>
          <h5>{currentPage}</h5>
        </div>
        <div className='profile'>
          <div className='profile-initials'>
            <h1>{`${initials[0]} ${initials[1]}`}</h1>
          </div>
          <h5
            className='name'
            onClick={() => setShowProfileModal(!showProfileModal)}
          >
            {name.split(` `)[0]}
          </h5>
          <FaChevronDown
            className='dropdown'
            onClick={() => setShowProfileModal(!showProfileModal)}
          />
          {showProfileModal ? <ProfileModal /> : null}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
