import React, { useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

const NewPost = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();
  const [postImage, setPostImage] = useState({});
  const [postContent, setPostContent] = useState(``);

  const formik = useFormik({
    initialValues: {
      title: ``,
      sportCategory: ``,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(`Post title is required`),
      sportCategory: Yup.string().required(`Sport category is required`),
    }),
    onSubmit() {
      createBlogPost();
    },
  });

  const createBlogPost = async () => {
    const formData = new FormData();
    formData.append(`image`, postImage);
    formData.append(`title`, formik.values.title);
    formData.append(`sportCategory`, formik.values.sportCategory);
    formData.append(`content`, postContent);

    try {
      setLoading(true);
      // eslint-disable-next-line
      await axios.post(`${baseURL}/news`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      toast.success(`Blog Post Created`);
      navigate(`/news-updates`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='new-post-page'>
      <Navbar
        category={`Menu > Blog Posts >`}
        categoryLink={`/news-updates`}
        currentPage={`New Blog Post`}
      />
      <Sidebar activePage={8} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='post-btn'>
          <FaChevronLeft onClick={() => navigate(`/vip-tips`)} />
          <h2 className='title'>New Blog Post</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='post-details'>
          <form action='' onSubmit={formik.handleSubmit}>
            {/* POST TITLE */}
            <div className='form-control'>
              <label
                htmlFor='title'
                className={
                  formik.errors.title && formik.touched.title ? `error` : ``
                }
              >
                {formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : `Post Title`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='title'
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='title'
              />
            </div>

            {/* POST TITLE */}
            <div className='form-control'>
              <label
                htmlFor='sportCategory'
                className={
                  formik.errors.sportCategory && formik.touched.sportCategory
                    ? `error`
                    : ``
                }
              >
                {formik.errors.sportCategory && formik.touched.sportCategory
                  ? formik.errors.sportCategory
                  : `Sport Category`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='sportCategory'
                value={formik.values.sportCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='sportCategory'
              />
            </div>

            {/* POST IMAGE */}
            <div className='form-control'>
              <label htmlFor='image'>
                Post Image
                <span>*</span>
              </label>
              <input
                type='file'
                accept='image/*'
                id='image'
                onChange={(e) => setPostImage(e.target.files[0])}
                name='image'
              />
            </div>

            {/* POST CONTENT */}
            <div className='form-control'>
              <label htmlFor='image'>
                Post Content
                <span>*</span>
              </label>
              <CKEditor
                data='<p>Initial content</p>'
                onChange={(event) => setPostContent(event.editor.getData())}
              />
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create Post {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default NewPost;
