import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGlobalContext } from '../../context/Context';
import { useGetRequest } from '../../custom-hooks/useGetRequest';
import { useEffect } from 'react';
import { FaGifts, FaUsers } from 'react-icons/fa';
import { BsNewspaper } from 'react-icons/bs';
import { SiExpertsexchange } from 'react-icons/si';

const Dashboard = () => {
  const { showProfileModal, baseURL } = useGlobalContext();

  // GET ALL CUSTOMERS
  const { data: customers, getDefault: getAllCustomers } = useGetRequest(
    `${baseURL}/users/count`
  );

  // GET ALL FREE ACCA TIPS
  const { data: freeAccaTips, getDefault: getAllFreeAccaTips } = useGetRequest(
    `${baseURL}/free-accumulator-tips`
  );

  // GET ALL NEWS POSTS
  const {
    data: { news },
    getDefault: getNewsPosts,
  } = useGetRequest(`${baseURL}/news/count`);
  // GET ALL OFFERS
  const { data: offers, getDefault: getOffers } = useGetRequest(
    `${baseURL}/offers`
  );

  useEffect(() => {
    getAllCustomers();
    getAllFreeAccaTips();
    getNewsPosts();
    getOffers();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='dashboard'>
      <Navbar category={`Menu >`} currentPage={`Dashboard`} />
      <Sidebar activePage={0} />
      <section className={`main-content ${showProfileModal ? `back` : null}`}>
        {/* CARDS SECTION */}
        <section className='cards'>
          <div className='card'>
            <div className='img'>
              <FaUsers />
            </div>
            <div className='info'>
              <h4 className='name'>Customers</h4>
              <h3 className='num'>
                {customers.users?.toLocaleString(`en-US`)}
              </h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <SiExpertsexchange />
            </div>
            <div className='info'>
              <h4 className='name'>Total Expert Singles</h4>
              <h3 className='num'>
                {freeAccaTips.count && freeAccaTips.count}
              </h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <BsNewspaper />
            </div>
            <div className='info'>
              <h4 className='name'>Total News Posts</h4>
              <h3 className='num'>{news}</h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <FaGifts />
            </div>
            <div className='info'>
              <h4 className='name'>Total Offers</h4>
              <h3 className='num'>{offers.count}</h3>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default Dashboard;
