import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import moment from 'moment';
import { AiTwotoneEdit } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

const Messages = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(``);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getAllMessages = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/contact-us`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setMessages(data.messages);
    } catch (error) {
      setLoading(false);
    }
  };

  // SEARCH MESSAGES
  const searchMessages = () => {
    const tempArray = messages.filter((message) =>
      message.email.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setMessages(tempArray);
    if (searchTerm === ``) {
      getAllMessages();
    }
  };

  // DELETE MESSAGES
  const deleteMessage = async (id) => {
    try {
      const { data } = await axios.delete(`${baseURL}/contact-us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.msg);
      getAllMessages();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    searchMessages();
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    getAllMessages();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='messages-page'>
      <Navbar category={`Management >`} currentPage={`Messages`} />
      <Sidebar activePage={14} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          pageTitle={`Messages`}
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className='table'>
          <div className='headers'>
            <p>Full Name</p>
            <p>Email</p>
            <p>Phone Number</p>
            <p>Message</p>
            <p>Date</p>
            <p>Actions</p>
          </div>

          {loading && messages ? (
            <Loader />
          ) : (
            <>
              {messages.map((message) => {
                const {
                  _id: id,
                  email,
                  message: msg,
                  createdAt,
                  name,
                } = message;

                return (
                  <div className='message-info' key={id}>
                    <p className='customer-name'>{name}</p>
                    <p className='email'>{email}</p>
                    <p className='message'>{msg}</p>
                    <p className='date'>{moment(createdAt).format(`LLL`)}</p>
                    <div className='btns'>
                      <AiTwotoneEdit
                        className='edit'
                        onClick={() => navigate(`/messages/${id}`)}
                      />
                      <RiDeleteBin2Fill
                        className='delete'
                        onClick={() => deleteMessage(id)}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Messages;
