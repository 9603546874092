import { useState } from "react";
import { useGlobalContext } from "../../context/Context";
import { FaTimes } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import spinner from "../../assets/white-spinner.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";

// MODAL FOR WHEN ADMIN WANTS TO UPDATE SOCIAL MEDIA LINK
export const UpdateSocialMediaLink = ({ linkId, refreshFunc }) => {
  const { setUpdateSocialMedialLink, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    validationSchema: Yup.object({
      link: Yup.string().required(`Link is required`),
    }),
    onSubmit() {
      updateLink();
    },
  });

  const updateLink = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.patch(
        `${baseURL}/social-media-links/${linkId}`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Link Updated`);
      setUpdateSocialMedialLink(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal add-free-in-play">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              setUpdateSocialMedialLink(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* HOME TEAM */}
          <div className="form-control">
            <label
              htmlFor="link"
              className={
                formik.errors.link && formik.touched.link ? `error` : ``
              }
            >
              {formik.errors.link && formik.touched.link
                ? formik.errors.link
                : `New Link`}
            </label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <button className="secondary" type="submit">
            Update Link {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE ADS
export const UpdateAd = ({ adId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [adImage, setAdImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      adLink: ``,
      adCaption: ``,
    },
    onSubmit() {
      updateAd();
    },
  });

  const formData = new FormData();
  const { adCaption, adLink } = formik.values;
  if (adImage) {
    formData.append(`adImage`, adImage);
  }
  if (adLink) {
    formData.append(`adLink`, formik.values.adLink);
  }
  if (adCaption) {
    formData.append(`adCaption`, formik.values.adCaption);
  }

  const updateAd = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/ads/${adId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Ad Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* NEW AD IMAGE */}
          <div className="form-control">
            <label htmlFor="adImage">New Ad Image</label>
            <input
              type="file"
              accept="image/*"
              id="adImage"
              name="adImage"
              onChange={(e) => setAdImage(e.target.files[0])}
            />
          </div>

          {/* NEW AD LINK */}
          <div className="form-control">
            <label htmlFor="adLink">New Ad Link</label>
            <input
              type="text"
              id="adLink"
              name="adLink"
              value={formik.values.adLink}
              onChange={formik.handleChange}
            />
          </div>

          {/* NEW AD CAPTION */}
          <div className="form-control">
            <label htmlFor="adCaption">New Ad Caption</label>
            <input
              type="text"
              id="adCaption"
              name="adCaption"
              value={formik.values.adCaption}
              onChange={formik.handleChange}
            />
          </div>

          <button className="secondary" type="submit">
            Update Ad {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO ADD A CLUB
export const AddClub = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [clubLogo, setClubLogo] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      clubName: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { clubName } = formik.values;
  formData.append(`clubLogo`, clubLogo);
  formData.append(`clubName`, clubName);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/clubs`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Club created`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* CLUB LOGO */}
          <div className="form-control">
            <label htmlFor="adImage">Club Logo</label>
            <input
              type="file"
              accept="image/*"
              id="adImage"
              name="adImage"
              onChange={(e) => setClubLogo(e.target.files[0])}
              required
            />
          </div>

          {/* CLUB NAME */}
          <div className="form-control">
            <label htmlFor="clubName">Club Name</label>
            <input
              type="text"
              id="clubName"
              name="clubName"
              value={formik.values.clubName}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className="secondary" type="submit">
            Add Club {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO ADD A CLUB
export const AddCompetition = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [competitionLogo, setCompetitionLogo] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      competitionName: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { competitionName } = formik.values;
  formData.append(`competitionLogo`, competitionLogo);
  formData.append(`competitionName`, competitionName);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/competitions`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Club created`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* CLUB LOGO */}
          <div className="form-control">
            <label htmlFor="adImage">Competition Logo</label>
            <input
              type="file"
              accept="image/*"
              id="adImage"
              name="adImage"
              onChange={(e) => setCompetitionLogo(e.target.files[0])}
              required
            />
          </div>

          {/* CLUB NAME */}
          <div className="form-control">
            <label htmlFor="competitionName">Competition Name</label>
            <input
              type="text"
              id="competitionName"
              name="competitionName"
              value={formik.values.competitionName}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className="secondary" type="submit">
            Add Club {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL VIP TIPS
export const DeleteAllVipTipsModal = () => {
  const { setDeleteVipTips, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteTips = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.delete(
        `${baseURL}/vip-tickets/delete-tickets`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Tickets Deleted`);
      setDeleteVipTips(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete all VIP Tips?</p>

        <div className="btns">
          <button className="grey" onClick={() => setDeleteVipTips(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteTips}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL BLOG POSTS
export const DeleteAllBlogPosts = () => {
  const { setDeleteBlogPosts, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteBlogPosts = async () => {
    try {
      setLoading(true);
      await axios.delete(`${baseURL}/news/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Blog POsts Deleted`);
      setDeleteBlogPosts(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete all Blog Posts?</p>

        <div className="btns">
          <button className="grey" onClick={() => setDeleteBlogPosts(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteBlogPosts}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL EMAILS
export const DeleteAllMails = () => {
  const { setDeleteMails, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteMails = async () => {
    try {
      setLoading(true);
      await axios.delete(`${baseURL}/mails/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Mails Deleted`);
      setDeleteMails(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete all Mails?</p>

        <div className="btns">
          <button className="grey" onClick={() => setDeleteMails(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteMails}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE BLOG POST
export const UpdateBlogPost = ({ postDetails }) => {
  const { setUpdateBlogPost, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [image, setImage] = useState(``);
  const [postContent, setPostContent] = useState(``);

  const formik = useFormik({
    initialValues: {
      title: postDetails.title,
      sportCategory: postDetails.sportCategory,
    },
    onSubmit() {
      createMatchOfTheDay();
    },
  });

  const createMatchOfTheDay = async () => {
    const { title, sportCategory } = formik.values;
    const formData = new FormData();

    if (title) {
      formData.append(`title`, title);
    }
    if (sportCategory) {
      formData.append(`sportCategory`, sportCategory);
    }
    if (postContent) {
      formData.append(`content`, postContent);
    }
    if (image) {
      formData.append(`image`, image);
    }

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.patch(
        `${baseURL}/news/${postDetails._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Post Updated`);
      setUpdateBlogPost(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-blog-post">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              setUpdateBlogPost(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* NEW IMAGE */}
          <div className="form-control">
            <label htmlFor="image">New Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* TITLE */}
          <div className="form-control">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* SPORT CATEGORY */}
          <div className="form-control">
            <label htmlFor="sportCategory">Sport Category</label>
            <input
              type="text"
              id="sportCategory"
              name="sportCategory"
              value={formik.values.sportCategory}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* CONTENT */}
          <div className="form-control">
            <label htmlFor="away-team">Post Details</label>
            <CKEditor
              data={postDetails.content}
              onChange={(event) => setPostContent(event.editor.getData())}
            />
          </div>

          <button className="secondary" type="submit">
            Update {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE NEWS
export const UpdateMail = ({ mailDetails }) => {
  const { setUpdateMail, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [mailContent, setMailContent] = useState(mailDetails.message);

  const formik = useFormik({
    initialValues: {
      subject: mailDetails.subject,
      usertype: mailDetails.usertype,
      message: mailDetails.message,
      recipients: mailDetails.recipients,
      status: String(mailDetails.status),
    },
    onSubmit() {
      updateMail();
    },
  });

  const updateMail = async () => {
    const formData = new FormData();
    formData.append(`recipients`, formik.values.recipients);
    formData.append(`usertype`, formik.values.usertype);
    formData.append(`subject`, formik.values.subject);
    formData.append(`status`, parseInt(formik.values.status, 10));
    formData.append(`message`, mailContent);

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.patch(
        `${baseURL}/mails/${mailDetails._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Mail Updated`);
      setUpdateMail(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-blog-post">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              setUpdateMail(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          <div className="form-control">
            <label
              htmlFor="usertype"
              className={
                formik.errors.usertype && formik.touched.usertype ? `error` : ``
              }
            >
              {formik.errors.usertype && formik.touched.usertype
                ? formik.errors.usertype
                : `Select Recipients`}{" "}
              <span>*</span>
            </label>
            <select
              type="text"
              id="usertype"
              value={formik.values.usertype}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="usertype"
            >
              <option value="0">All Customers</option>
              <option value="1">Others</option>
            </select>
          </div>

          {/* RECIPIENTS */}
          {formik.values.usertype === "1" && (
            <div className="form-control">
              <label
                htmlFor="recipients"
                className={
                  formik.errors.recipients && formik.touched.recipients
                    ? `error`
                    : ``
                }
              >
                {formik.errors.recipients && formik.touched.recipients
                  ? formik.errors.recipients
                  : `Recipients`}{" "}
                <span>*</span>
              </label>
              <textarea
                type="text"
                id="recipients"
                value={formik.values.recipients}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="recipients"
                placeholder="Please enter the email addresses separated by comma eg: test@test.com,demo@demo.com"
                rows={10}
              />
            </div>
          )}

          {/* TITLE */}
          <div className="form-control">
            <label htmlFor="title">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {/* CONTENT */}
          <div className="form-control">
            <label htmlFor="away-team">Body</label>
            <CKEditor
              initData={mailDetails.message}
              data={mailDetails.message}
              editorUrl="https://crons.kmanalysis.com/plugins/ckeditor/ckeditor.js"
              onChange={(event) => setMailContent(event.editor.getData())}
            />
          </div>
          {/* USERTYPE */}
          <div className="form-control">
            <label
              htmlFor="status"
              className={
                formik.errors.status && formik.touched.status ? `error` : ``
              }
            >
              {formik.errors.status && formik.touched.status
                ? formik.errors.status
                : `Status`}{" "}
              <span>*</span>
            </label>
            <select
              type="text"
              id="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="status"
            >
              <option value="1">Send Now</option>
              <option value="0">Send Later</option>
            </select>
          </div>

          <button className="secondary" type="submit">
            Update {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE A CUSTOMER
export const DeleteCustomer = ({ userId, refreshFunc }) => {
  const { setDeleteCustomer, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteCustomer = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.delete(`${baseURL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Customer Deleted`);
      setDeleteCustomer(false);
      navigate(`/customers`);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete this customer?</p>

        <div className="btns">
          <button className="grey" onClick={() => setDeleteCustomer(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteCustomer}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL CLUBS
export const DeleteClubs = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteClubs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/clubs/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want delete all the clubs?</p>

        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteClubs}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL COMPETITIONS
export const DeleteCompetitions = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteClubs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/competitions/delete-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want delete all the competitions?</p>

        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteClubs}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE AN OFFER
export const CreateOffer = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
      caption: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { caption, link } = formik.values;
  formData.append(`image`, image);
  formData.append(`link`, link);
  formData.append(`caption`, caption);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/offers`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Offer Created Successfully`);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* OFFER IMAGE */}
          <div className="form-control">
            <label htmlFor="image">Offer Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* OFFER LINK */}
          <div className="form-control">
            <label htmlFor="link">Offer Link</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          {/* OFFER CAPTION */}
          <div className="form-control">
            <label htmlFor="captionbName">Offer Caption</label>
            <input
              type="text"
              id="caption"
              name="caption"
              value={formik.values.caption}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className="secondary" type="submit">
            Create Offer {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE OFFERS
export const UpdateOffer = ({ offerId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
      caption: ``,
    },
    onSubmit() {
      updateAd();
    },
  });

  const formData = new FormData();
  const { caption, link } = formik.values;
  if (image) {
    formData.append(`image`, image);
  }
  if (link) {
    formData.append(`link`, link);
  }
  if (caption) {
    formData.append(`caption`, caption);
  }

  const updateAd = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/offers/${offerId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Offer Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* NEW OFFER IMAGE */}
          <div className="form-control">
            <label htmlFor="image">New Offer Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* NEW OFFER LINK */}
          <div className="form-control">
            <label htmlFor="link">New Offer Link</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
            />
          </div>

          {/* NEW OFFER CAPTION */}
          <div className="form-control">
            <label htmlFor="caption">New Offer Caption</label>
            <input
              type="text"
              id="caption"
              name="caption"
              value={formik.values.caption}
              onChange={formik.handleChange}
            />
          </div>

          <button className="secondary" type="submit">
            Update Offer {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL OFFERS
export const DeleteOffers = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteOffers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/offers/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-all-offers">
        <p>Are you sure you want delete all the offers?</p>

        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteOffers}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE A POP-UP
export const CreatePopup = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      createPopup();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  formData.append(`image`, image);
  formData.append(`link`, link);

  const createPopup = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/popups`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Pop-up Created Successfully`);
      dismissFunc(false);
      console.log(data);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* POP-UP IMAGE */}
          <div className="form-control">
            <label htmlFor="image">Pop-up Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* POP-UP LINK */}
          <div className="form-control">
            <label htmlFor="link">Pop-up Link</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className="secondary" type="submit">
            Create Pop-up {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE A POP-UP
export const UpdatePopup = ({ popupId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      updatePopup();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  if (image) {
    formData.append(`image`, image);
  }
  if (link) {
    formData.append(`link`, link);
  }

  const updatePopup = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/popups/${popupId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Pop-up Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* NEW POP-UP IMAGE */}
          <div className="form-control">
            <label htmlFor="image">New Pop-up Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* NEW POP-UP LINK */}
          <div className="form-control">
            <label htmlFor="link">New Pop-up Link</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
            />
          </div>

          <button className="secondary" type="submit">
            Update Pop-up {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL POP-UPS
export const DeletePopups = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deletePopups = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/popups/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-all-offers">
        <p>Are you sure you want delete all pop-ups?</p>

        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deletePopups}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL FREE ACCUMULATOR TIPS
export const DeleteAllFreeAccaTiipsModal = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/free-accumulator-tips/delete-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete all Free Accumulator Tips?</p>
        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteTips}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL CUSTOMERS
export const DeleteAllCustomersModal = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteCustomers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/users/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-vip-tips">
        <p>Are you sure you want to delete all Customers?</p>
        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteCustomers}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE A GIF
export const CreateGif = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [mobileImage, setMobileImage] = useState(``);
  const [desktopImage, setDesktopImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      createGif();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  formData.append(`mobileImage`, mobileImage);
  formData.append(`desktopImage`, desktopImage);
  formData.append(`link`, link);

  const createGif = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/gifs`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`GIF Created Successfully`);
      dismissFunc(false);
      console.log(data);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal update-ad">
        {/* CLOSE MODAL BTN */}
        <div className="close-btn">
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action="" onSubmit={formik.handleSubmit}>
          {/* MOBILE IMAGE */}
          <div className="form-control">
            <label htmlFor="image">Mobile Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setMobileImage(e.target.files[0])}
              required
            />
          </div>

          {/* DESKTOP IMAGE */}
          <div className="form-control">
            <label htmlFor="image">Desktop Image</label>
            <input
              type="file"
              accept="image/*"
              id="image"
              name="image"
              onChange={(e) => setDesktopImage(e.target.files[0])}
              required
            />
          </div>

          {/* LINK */}
          <div className="form-control">
            <label htmlFor="link">GIF Link</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className="secondary" type="submit">
            Create GIF {loading && <img src={spinner} alt="" />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL GIFS
export const DeleteGifs = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteGifs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/gifs/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className="modal-container" id="delete-container">
      <div className="modal delete-all-offers">
        <p>Are you sure you want delete all GIFs?</p>

        <div className="btns">
          <button className="grey" onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className="red" onClick={deleteGifs}>
            Delete {loading && <img src={spinner} alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};
