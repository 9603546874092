import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import { CreateGif, DeleteGifs } from '../../components/Modals/Modals';
import { toast } from 'react-toastify';

const Gifs = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [createGif, setCreateGif] = useState(false);
  const [deleteGifs, setDeleteGifs] = useState(false);
  const [gifs, setGifs] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  //   GET ALL GIFS
  const getGifs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/gifs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGifs(data.gifs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGifs();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='gifs-page'>
      <Navbar category={`Management >`} currentPage={`GIFs`} />
      <Sidebar activePage={16} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>GIFs</h2>
        </div>

        <div className='create-del-btns'>
          <button
            className='primary'
            onClick={() => {
              if (gifs.length > 0) {
                toast.error(`Please delete old GIFs first`);
              } else {
                setCreateGif(true);
              }
            }}
          >
            Create GIF
          </button>
          <button className='red' onClick={() => setDeleteGifs(true)}>
            Delete All GIFs
          </button>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {gifs.map((gif, gifIndex) => {
              const { link, desktopImage, mobileImage } = gif;
              return (
                <div className='link-details' key={gifIndex}>
                  <h3 className='title'>GIF Details</h3>
                  <div className='form-control'>
                    <label htmlFor=''>GIF Link</label>
                    <h3>{link}</h3>
                  </div>

                  <div className='form-control'>
                    <label htmlFor=''>Mobile GIF</label>
                    <div className='img'>
                      <img
                        src={`https://api.kmanalysis.com${mobileImage}`}
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='form-control'>
                    <label htmlFor=''>Desktop GIF</label>
                    <div className='img'>
                      <img
                        src={`https://api.kmanalysis.com${desktopImage}`}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {createGif && (
        <CreateGif dismissFunc={setCreateGif} refreshFunc={getGifs} />
      )}

      {deleteGifs && (
        <DeleteGifs dismissFunc={setDeleteGifs} refreshFunc={getGifs} />
      )}
    </main>
  );
};

export default Gifs;
