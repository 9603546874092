import React, { useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';

const NewOverOnePointFiveTip = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      awayTeam: ``,
      league: ``,
      matchTime: ``,
    },
    validationSchema: Yup.object({
      homeTeam: Yup.string().required(`Home team is required`),
      awayTeam: Yup.string().required(`Away team is required`),
      league: Yup.string().required(`League is required`),
      matchTime: Yup.string().required(`Match time is required`),
    }),
    onSubmit() {
      createVipTip();
    },
  });

  // CREATE TIP
  const createVipTip = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const { data } = await axios.post(
        `${baseURL}/over-1.5-tips`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/over-1.5-tips`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='new-over-one-point-five-tip-page'>
      <Navbar
        category={`Menu > Over 1.5 Tips >`}
        categoryLink={`/over-1.5-tips`}
        currentPage={`New Tip`}
      />
      <Sidebar activePage={3} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/over-1.5-tips`)} />
          <h2 className='title'>New Tip</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='ticket-details'>
          <h3 className='title'>Tip Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* HOME TEAM */}
            <div className='form-control'>
              <label
                htmlFor='homeTeam'
                className={
                  formik.errors.homeTeam && formik.touched.homeTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeam && formik.touched.homeTeam
                  ? formik.errors.homeTeam
                  : `Home Team`}{' '}
                <span>*</span>
              </label>
              <input
                name='homeTeam'
                id='homeTeam'
                value={formik.values.homeTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* AWAY TEAM */}
            <div className='form-control'>
              <label
                htmlFor='awayTeam'
                className={
                  formik.errors.awayTeam && formik.touched.awayTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeam && formik.touched.awayTeam
                  ? formik.errors.awayTeam
                  : `Away Team`}{' '}
                <span>*</span>
              </label>
              <input
                name='awayTeam'
                id='awayTeam'
                value={formik.values.awayTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* LEAGUE */}
            <div className='form-control'>
              <label
                htmlFor='league'
                className={
                  formik.errors.league && formik.touched.league ? `error` : ``
                }
              >
                {formik.errors.league && formik.touched.league
                  ? formik.errors.league
                  : `League Initials`}{' '}
                <span>*</span>
              </label>
              <input
                name='league'
                id='league'
                value={formik.values.league}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* MATCH TIME */}
            <div className='form-control'>
              <label
                htmlFor='matchTime'
                className={
                  formik.errors.matchTime && formik.touched.matchTime
                    ? `error`
                    : ``
                }
              >
                {formik.errors.matchTime && formik.touched.matchTime
                  ? formik.errors.matchTime
                  : `Match Time`}{' '}
                <span>*</span>
              </label>
              <input
                name='matchTime'
                id='matchTime'
                type='datetime-local'
                value={formik.values.matchTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default NewOverOnePointFiveTip;
