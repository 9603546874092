import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import { UpdateSocialMediaLink } from '../../components/Modals/Modals';

const SocialMediaLinks = () => {
  const navigate = useNavigate();
  const {
    showProfileModal,
    baseURL,
    updateSocialMedialLink,
    setUpdateSocialMedialLink,
  } = useGlobalContext();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [linkId, setLinkId] = useState(``);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  //   FETCH LINKS
  const fetchLinks = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/social-media-links`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLinks(data.links);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='social-media-links-page'>
      <Navbar category={`Management >`} currentPage={`Social Media Links`} />
      <Sidebar activePage={13} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Links</h2>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='links-container'>
              {links.map((item, itemIndex) => {
                return (
                  <div className='link' key={itemIndex}>
                    <div className='info'>
                      <h3>
                        {item.name === `facebook` ? `Whatsapp` : item.name}
                      </h3>
                      <p>{item.link}</p>
                    </div>
                    <button
                      className='primary'
                      onClick={() => {
                        setLinkId(item._id);
                        setUpdateSocialMedialLink(true);
                      }}
                    >
                      Update Link
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {updateSocialMedialLink && (
        <UpdateSocialMediaLink linkId={linkId} refreshFunc={fetchLinks} />
      )}
    </main>
  );
};

export default SocialMediaLinks;
