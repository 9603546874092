import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/Context";
import axios from "axios";
import { FaChevronLeft } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import spinner from "../../assets/white-spinner.svg";
import { UpdateMail } from "../../components/Modals/Modals";

const STATUSES = [
  { title: "DRAFT", color: "info" },
  { title: "QUEUED FOR SENDING", color: "success" },
  { title: "SENT", color: "success" },
  { title: "FAILED", color: "danger" },
  { title: "SENDING", color: "info" },
];
const ViewMail = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL, updateMails, setUpdateMail } =
    useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [mail, setMail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const fetchPostDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/mails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMail(data[0]);
      setLoading(false);
      if (data.length < 1) {
        navigate(`/emails`);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostDetails();
    //eslint-disable-next-line
  }, [id, updateMails]);

  const deleteMail = async (id) => {
    try {
      setDeleteLoading(true);
      await axios.delete(`${baseURL}/mails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Mail deleted successfully`);
      navigate(`/emails`);
      setDeleteLoading(false);
    } catch (error) {
      console.log(error.response.data.msg);
      setDeleteLoading(false);
    }
  };

  return (
    <main className="view-mail">
      <Navbar
        category={`Management > Emails >`}
        categoryLink={`/emails`}
        currentPage={`View Mail`}
      />
      <Sidebar activePage={20} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className="mail-btn">
          <FaChevronLeft onClick={() => navigate(`/emails`)} />
          <h2 className="title">Mail</h2>
        </div>

        {/* CUSTOMER INFORMATION BOX */}
        {loading ? (
          <Loader />
        ) : (
          <div className="mail-details">
            {/* TITLE */}
            <div className="form-control">
              <label htmlFor="">Mail Recipient</label>
              <h3>{`${
                (mail?.usertype === "0" && "ALL CUSTOMERS") || mail?.recipients
              }`}</h3>
            </div>

            {/* SPORT CATEGORY */}
            <div className="form-control">
              <label htmlFor="">Mail Subject</label>
              <h3>{mail?.subject}</h3>
            </div>

            {/* CONTENT */}
            <div className="form-control">
              <label htmlFor="">Mail Body</label>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: mail?.message }}
              ></div>
            </div>

            {/* IMAGE */}
            <div className="form-control">
              <label htmlFor="">Mail Status</label>
              <div className="img">
                <p style={{ fontWeight: 600 }}>
                  {STATUSES[parseInt(mail?.status, 10)].title}
                </p>
              </div>
            </div>

            <div className="btns">
              <button
                className="primary"
                type="button"
                onClick={() => setUpdateMail(true)}
              >
                Update Mail
              </button>
              <button className="red" onClick={() => deleteMail(mail?._id)}>
                Delete Mail
                {deleteLoading && <img src={spinner} alt="" />}
              </button>
            </div>
          </div>
        )}
      </div>
      {updateMails && <UpdateMail mailDetails={mail} />}
    </main>
  );
};

export default ViewMail;
