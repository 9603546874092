import React, { useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FaChevronLeft, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';

const NewAdmin = () => {
  const { showProfileModal, baseURL, revealPassword } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: ``,
      name: ``,
      role: ``,
      password: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is Required`),
      name: Yup.string().required(`Admin name is Required`),
      role: Yup.string().required(`Away team is required`),
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required(`Password is required`),
    }),
    onSubmit() {
      createSubAdmin();
    },
  });

  const createSubAdmin = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const { data } = await axios.post(
        `${baseURL}/sub-admins`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      console.log(data);
      toast.success(data.msg);
      navigate(`/admins`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='new-admin-page'>
      <Navbar
        category={`Menu > Admins >`}
        categoryLink={`/admins`}
        currentPage={`New Admin`}
      />
      <Sidebar activePage={16} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/admins`)} />
          <h2 className='title'>New Admin</h2>
        </div>

        {/* ADMIN DETAILS BOX */}
        <div className='ticket-details'>
          <h3 className='title'>Admin Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* ADMIN EMAIL */}
            <div className='form-control'>
              <label
                htmlFor='email'
                className={
                  formik.errors.email && formik.touched.email ? `error` : ``
                }
              >
                {formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : `Email`}{' '}
                <span>*</span>
              </label>
              <input
                type='email'
                name='email'
                id='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ADMIN NAME */}
            <div className='form-control'>
              <label
                htmlFor='name'
                className={
                  formik.errors.name && formik.touched.name ? `error` : ``
                }
              >
                {formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : `Name`}{' '}
                <span>*</span>
              </label>
              <input
                name='name'
                id='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ADMIN ROLE */}
            <div className='form-control'>
              <label
                htmlFor='role'
                className={
                  formik.errors.role && formik.touched.role ? `error` : ``
                }
              >
                {formik.errors.role && formik.touched.role
                  ? formik.errors.role
                  : `Role`}{' '}
                <span>*</span>
              </label>
              <select
                name='role'
                id='role'
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Admin Role
                </option>
                <option value='publisher-admin'>Publisher Admin</option>
                <option value='blogger-admin'>Blogger Admin</option>
              </select>
            </div>

            {/* ADMIN PASSWORD */}
            <div className='form-control'>
              <label
                htmlFor='password'
                className={
                  formik.errors.password && formik.touched.password
                    ? `error`
                    : ``
                }
              >
                {formik.errors.password && formik.touched.password
                  ? formik.errors.password
                  : `Password`}{' '}
                <span>*</span>
              </label>
              <div className='password-container'>
                <input
                  type='password'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  id='password'
                />
                {showPassword ? (
                  <FaEyeSlash
                    className='icon'
                    onClick={() => {
                      revealPassword(`password`);
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <FaEye
                    className='icon'
                    onClick={() => {
                      revealPassword(`password`);
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
              </div>
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create Admin {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default NewAdmin;
