import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { AiTwotoneEdit } from 'react-icons/ai';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

const Admins = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getAdmins = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/sub-admins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      console.log(data);
      setAdmins(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteAdmin = async (id) => {
    try {
      const { data } = await axios.delete(`${baseURL}/sub-admins/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.msg);
      getAdmins();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, []);

  const searchByTeams = () => {
    const tempArray = admins.filter((admin) =>
      admin.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setAdmins(tempArray);
    if (searchTerm === ``) {
      getAdmins();
    }
  };

  useEffect(() => {
    searchByTeams();
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <main className='admins-page'>
      <Navbar category={`Menu >`} currentPage={`Admins`} />
      <Sidebar activePage={18} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        >
          <button
            className='primary'
            onClick={() => navigate(`/admins/new-admin`)}
          >
            Create Admin
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Name</p>
            <p>Role</p>
            <p>Date Created</p>
            <p>Action</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {admins &&
                admins.map((admin) => {
                  const { _id, name, role, createdAt } = admin;
                  return (
                    <div className='tip-info' key={_id}>
                      <Link className='name'>{name}</Link>
                      <Link className='league'>
                        {role === `publisher-admin`
                          ? `Publisher Admin`
                          : role === `blogger-admin`
                          ? `Blogger Admin`
                          : ``}
                      </Link>
                      <Link className='status'>
                        {moment(createdAt).format(`LLL`)}
                      </Link>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() => navigate(`/admins/${_id}`)}
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => deleteAdmin(_id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Admins;
