import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  CreateOffer,
  DeleteOffers,
  UpdateOffer,
} from '../../components/Modals/Modals';
import { toast } from 'react-toastify';

const Offers = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [updateOffer, setUpdateOffer] = useState(false);
  const [offerId, setOfferId] = useState(``);
  const [createOffer, setCreateOffer] = useState(false);
  const [deleteOffers, setDeleteOffers] = useState(false);
  const [offers, setOffers] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  //   GET ALL OFFERS
  const getOffers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/offers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffers(data.offers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOffers();
    // eslint-disable-next-line
  }, [updateOffer]);

  const deleteOffer = async (id) => {
    try {
      const { data } = await axios.delete(`${baseURL}/offers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.msg);
      getOffers();
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='offers-page'>
      <Navbar category={`Management >`} currentPage={`Offers`} />
      <Sidebar activePage={15} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Offers</h2>
        </div>

        <div className='create-del-btns'>
          <button className='primary' onClick={() => setCreateOffer(true)}>
            Create Offer
          </button>
          <button className='red' onClick={() => setDeleteOffers(true)}>
            Delete All Offers
          </button>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {offers.map((offer, offerIndex) => {
              const { link, image, caption, _id } = offer;
              return (
                <div className='link-details' key={offerIndex}>
                  <h3 className='title'>Offer {offerIndex + 1} Details</h3>
                  <div className='form-control'>
                    <label htmlFor=''>Offer Link</label>
                    <h3>{link}</h3>
                  </div>
                  <div className='form-control'>
                    <label htmlFor=''>Offer Caption</label>
                    <h3>{caption}</h3>
                  </div>
                  <div className='form-control'>
                    <label htmlFor=''>Offer Image</label>
                    <div className='img'>
                      <img src={`https://api.kmanalysis.com${image}`} alt='' />
                    </div>
                  </div>
                  <div className='btns'>
                    <button
                      className='primary'
                      onClick={() => {
                        setOfferId(_id);
                        setUpdateOffer(true);
                      }}
                    >
                      Update Offer
                    </button>
                    <button
                      className='red'
                      onClick={() => {
                        deleteOffer(_id);
                      }}
                    >
                      Delete Offer
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {createOffer && (
        <CreateOffer dismissFunc={setCreateOffer} refreshFunc={getOffers} />
      )}
      {updateOffer && (
        <UpdateOffer offerId={offerId} dismissFunc={setUpdateOffer} />
      )}
      {deleteOffers && (
        <DeleteOffers dismissFunc={setDeleteOffers} refreshFunc={getOffers} />
      )}
    </main>
  );
};

export default Offers;
